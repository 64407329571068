<template>
  <div
    class="loading-container"
    :style="additionStyle"
  >
    <IconBase
      width="2rem"
      height="2rem"
      view-box="0 0 512 512"
    >
      <IconArcThird />
    </IconBase>
  </div>
</template>

<script>
import IconBase from '@/components/common/IconBase'
import IconArcThird from '@/components/icons/IconArcThird'
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Loading',
  components: {
    IconArcThird,
    IconBase
  },
  props: {
    additionStyle: {
      type: Object,
      default () {
        return {}
      }
    },
    iconWidth: {
      type: String,
      default: '2rem'
    },
    iconHeight: {
      type: String,
      default: '2rem'
    }
  },
  data () {
    return {
      degree: 0,
      intervalID: 0
    }
  }
})
</script>

<style lang="scss" scoped>
.loading-container {
  text-align: center;
  color: rgba(120, 120, 120, 0.5);
  svg {
    animation: fa-spin 1s linear infinite;
  }
}
</style>
