import { request } from '@/network/request'

const functionGroup = 'ClientSetting'

export function updateWebsiteSetting (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'updateWebsiteSetting',
      ...data
    }
  })
}
