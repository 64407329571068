<template>
  <div
    class="input-container"
    @click="focus"
  >
    <label class="input-label">
      <input
        ref="input"
        :type="type"
        class="input"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <font-awesome-icon icon="pen" />
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Input1',
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/styles/constants";

.input-container {
  border-radius: 0.6rem;
  height: 4rem;
  line-height: 3.8rem;
  padding: 0 1rem;
  transition: 0.25s;
  box-sizing: border-box;
  background-color: rgb(220, 220, 220);
  cursor: text;
}

.input-label {
  text-align: left;
  font-size: 1.8rem;
  color: rgb(170, 170, 170);
  cursor: text;
}

.input {
  height: 2.4rem;
  width: calc(100% - 2.4rem);
  font-size: 1.6rem;
  border: none;
  padding: 0;
  margin-right: 0.6rem;
  -webkit-appearance: none;
  outline: none;
  background-color: transparent;
  color: $font_color;
}
</style>
