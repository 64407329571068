<template>
  <div
    class="bandori-character-icon-container"
    :style="sizeStyle"
  >
    <div
      class="bandori-character-icon-item"
    >
      <div
        class="bandori-image-loading"
        :style="loadingStyle"
      />
    </div>
    <div
      class="bandori-character-icon-item"
      :style="imageStyle"
    />
    <div
      class="bandori-character-icon-item"
      :style="frameStyle"
    />
    <div
      class="bandori-character-icon-item"
      :style="attributeStyle"
    />
    <div
      class="bandori-character-icon-item"
      :style="rarityStyle"
    />
    <div
      v-if="bandId !== 0"
      class="bandori-character-icon-item"
      :style="bandStyle"
    />
    <div
      v-if="labelText !== ''"
      class="bandori-character-icon-item bandori-character-icon-label-container"
    >
      <div
        class="bandori-character-icon-label"
        :style="labelStyle"
      >
        {{ labelText }}
      </div>
      <div
        v-if="skillLabelText !== ''"
        class="bandori-character-icon-label bandori-character-icon-skill-label"
        :style="skillLabelStyle"
      >
        {{ skillLabelText }}
      </div>
    </div>
  </div>
</template>

<script>
import avatarImage from '@/assets/images/user_avatar.png'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BandoriCharacterIcon',
  props: {
    size: {
      type: Number,
      default: 9
    },
    imageUrl: {
      type: String,
      default: avatarImage
    },
    attribute: {
      type: String,
      default: 'powerful'
    },
    rarity: {
      type: Number,
      default: 1
    },
    bandId: {
      type: Number,
      default: 0
    },
    isAfterTraining: {
      type: Boolean,
      default: false
    },
    labelText: {
      type: [String, Number],
      default: ''
    },
    skillLabelText: {
      type: [String, Number],
      default: ''
    },
    skillType: {
      type: String,
      default: 'other'
    },
    displayMarginTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loadingStyle: function () {
      return {
        width: this.size / 2 + 'rem',
        height: this.size / 2 + 'rem',
        margin: this.size / 4 + 'rem'
      }
    },
    sizeStyle: function () {
      const style = {
        width: this.size + 'rem',
        height: this.size + 'rem',
      }
      if (this.displayMarginTop) {
        style.marginTop = '1rem'
      }
      return style
    },
    imageStyle: function () {
      return {
        backgroundImage: 'url("' + this.imageUrl + '")'
      }
    },
    frameStyle: function () {
      if (this.rarity === 1) {
        return {
          backgroundImage: 'url("/img/bandori/icon_frame_1_' + this.attribute + '.png")'
        }
      } else {
        return {
          backgroundImage: 'url("/img/bandori/icon_frame_' + this.rarity + '.png")'
        }
      }
    },
    attributeStyle: function () {
      return {
        backgroundImage: 'url("/img/bandori/icon_attribute_' + this.attribute + '.png")'
      }
    },
    rarityStyle: function () {
      if (this.rarity > 2) {
        if (this.isAfterTraining) {
          return {
            backgroundImage: 'url("/img/bandori/icon_rarity_' + this.rarity + '_after_training.png")'
          }
        } else {
          return {
            backgroundImage: 'url("/img/bandori/icon_rarity_' + this.rarity + '_normal.png")'
          }
        }
      } else {
        return {
          backgroundImage: 'url("/img/bandori/icon_rarity_' + this.rarity + '_normal.png")'
        }
      }
    },
    bandStyle: function () {
      return {
        backgroundImage: 'url("/img/bandori/icon_band_' + this.bandId + '.png")'
      }
    },
    labelStyle: function () {
      return {
        fontSize: 2.2 / 18 * this.size + 'rem',
        marginTop: 14.4 / 18 * this.size + 'rem',
        marginRight: 1.4 / 18 * this.size + 'rem'
      }
    },
    skillLabelStyle: function () {
      const response = {
        right: 0.8 / 18 * this.size + 'rem',
        bottom: 3.7 / 18 * this.size + 'rem',
        fontSize: 2.2 / 18 * this.size + 'rem',
        paddingLeft: 0.4 / 18 * this.size + 'rem',
        paddingRight: 0.4 / 18 * this.size + 'rem'
      }
      switch (this.skillType) {
        case 'score':
          response.background = '#4057E3'
          break
        case 'judge_score':
          response.background = 'linear-gradient(45deg, #FF3258 50%, #4057E3 0%)'
          break
        case 'heal_score':
          response.background = 'linear-gradient(45deg, #40C423 50%, #4057E3 0%)'
          break
        case 'heal_judge':
          response.background = 'linear-gradient(45deg, #40C423 50%, #FF3258 0%)'
          break
        case 'guard_score':
          response.background = 'linear-gradient(45deg, #FF8400 50%, #4057E3 0%)'
          break
        case 'score_heal_guard':
          response.background = 'conic-gradient(#4057E3 0%, #4057E3 33%, #40C423 33%, #40C423 66%, #FF8400 66%, #FF8400 100%)'
          break
        default:
          response.background = '#585858'
      }
      return response
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/constants";

.bandori-character-icon-container {
  position: relative;
  display: inline-block;
}

.bandori-character-icon-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: {
    size: contain;
    repeat: no-repeat;
  };
}

.bandori-character-icon-label-container {
  background-image: url("../../../assets/images/icon_parameter_label.png");
}

.bandori-character-icon-label {
  color: white;
  text-align: right;
  user-select: none;
}

.bandori-character-icon-skill-label {
  display: inline-block;
  position: absolute;
}
</style>
