<template>
  <div class="control-panel">
    <div>
      <Button1
        class="button-2"
        color="3"
        shape="circle"
        :config="{ type: 'button' }"
        :display-badge="chatUnreadMark"
        @click="openDialog('chatRoom', true)"
      >
        <font-awesome-icon icon="comment" />
      </Button1>
    </div>
    <div class="line-container-lite">
      <Button1
        class="button-2"
        color="1"
        shape="circle"
        :config="{ type: 'button' }"
        @click="openDialog('eventTierPrediction')"
      >
        <font-awesome-icon icon="chart-line" />
      </Button1>
    </div>
    <div class="line-container-lite">
      <Button1
        class="button-2"
        color="1"
        shape="circle"
        :config="{ type: 'button' }"
        @click="openDialog('setRoomNumberFilter')"
      >
        <font-awesome-icon icon="filter" />
      </Button1>
    </div>
    <div class="line-container-lite">
      <Button1
        class="button-2"
        color="1"
        shape="circle"
        :config="{ type: 'button' }"
        :disabled="sendRoomNumberDisable"
        @click="!sendRoomNumberDisable ? openDialog('sendRoomNumber', true) : null"
      >
        <font-awesome-icon icon="plus" />
      </Button1>
    </div>
  </div>
</template>

<script>
import Button1 from '@/components/common/Button1'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RoomNumberControlPanel',
  components: {
    Button1
  },
  emits: ['openDialog'],
  data () {
    return {
      chatUnreadMark: false
    }
  },
  computed: {
    sendRoomNumberDisable () {
      return this.$store.state.account.banStatus.isBanned
        && new Date().getTime() < this.$store.state.account.banStatus.endTime;
    }
  },
  methods: {
    openDialog (name, checkLogin = false) {
      if (checkLogin) {
        if (!this.$store.state.account.loginStatus) {
          this.$router.push('/login')
          return
        }
      }
      this.$emit('openDialog', name)
    },
    setChatUnreadBadge (status) {
      this.chatUnreadMark = status
    }
  }
})
</script>

<style lang="scss" scoped>
.control-panel {
  position: fixed;
  right: calc(50% - 46rem);
  bottom: 10rem;
  transition: 0.4s;
  @media (max-width: 960px) {
    right: 1.5rem;
    bottom: 2rem;
  }
}
</style>
