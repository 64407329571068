<template>
  <div id="app">
    <Navbar />
    <Loading
      v-if="isLoading"
      icon-width="2.4rem"
      icon-height="2.4rem"
      :addition-style="{ position: 'fixed', top: '6rem', right: '1rem'}"
    />
    <router-view v-if="$store.state.account.initialized" />
    <NoticeContainer />
    <Background />
  </div>
</template>

<script>
import Navbar from '@/components/project/Navbar/Navbar'
import NoticeContainer from '@/components/project/NoticeContainer'
import { initializeAccountSetting } from '@/network/mainAction'
import Background from '@/components/project/Background2'
import Loading from '@/components/common/Loading'
import cloneDeep from 'lodash/cloneDeep'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Loading,
    Background,
    Navbar,
    NoticeContainer
  },
  data () {
    return {
      isLoading: true
    }
  },
  created () {
    this.$globalFunctions.checkUserDeviceType()
    let setting
    if (this.$cookie.isCookieAvailable('setting')) {
      setting = this.$cookie.getCookie('setting')
      this.$store.commit('misc/setSetting', cloneDeep(setting))
    }
    if (this.$cookie.isCookieAvailable('token')) {
      const token = this.$cookie.getCookie('token')
      initializeAccountSetting(
        this.$globalFunctions.generateRequestHeader(token)
      ).then(response => {
        this.isLoading = false
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            this.$store.commit('account/setToken', token)
            this.$store.commit('account/setID', responseData.user_id)
            this.$store.commit('account/setAvatar', responseData.avatar)
            this.$store.commit('account/setRole', responseData.role)
            this.$store.commit('account/setBanStatus', responseData.ban_status)
            this.$store.commit('account/setFollowingUser', responseData.following_user)
            setting = responseData.website_setting
            this.$store.commit('misc/setSetting', responseData.website_setting)
            this.$store.commit('account/setLoginStatus', true)
            this.$store.commit('account/setInitializeStatus', true)

            this.$cookie.setCookie('token', token, { expire: 2592000 })
            if (setting) {
              this.$cookie.setCookie('setting', setting, { expire: 2592000 })
            }
          },
          () => {
            this.$cookie.removeCookie('token')
            this.$store.commit('account/setInitializeStatus', true)
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    } else {
      this.isLoading = false
      this.$store.commit('account/setAvatar', null)
      this.$store.commit('account/setInitializeStatus', true)
      if (setting) {
        this.$cookie.setCookie('setting', setting, { expire: 2592000 })
      }
    }
  }
})
</script>

<style lang="scss">
@import "assets/styles/index";
</style>
