<template>
  <div class="user-brief-info-container">
    <div class="avatar-container">
      <div
        class="user-avatar"
        :style="avatarStyle"
      />
    </div>
    <div class="info-container">
      <div class="flex-container">
        <div class="brief-info-username-container">
          <span class="user-info-username">{{ briefInfo.username }}</span>
        </div>
        <div v-if="briefInfo.user_id !== $store.state.account.id">
          <Button1
            v-if="isFollowing"
            class="button-1 user-brief-info-following-button"
            color="2"
            :config="{ type: 'button' }"
            @click="$emit('unfollowUser')"
          >
            取消关注
          </Button1>
          <Button1
            v-else
            class="button-1 user-brief-info-following-button"
            color="1"
            :config="{ type: 'button' }"
            @click="$emit('followUser')"
          >
            关注
          </Button1>
        </div>
      </div>
      <div class="line-container-lite">
        <span>{{ briefInfo.introduction }}</span>
      </div>
      <div v-if="displayPlayerBriefData">
        <div class="line-container">
          <div v-if="isLoading.degreeData || isLoading.cardData">
            <div class="skeleton-animation room-number-skeleton-bar" />
          </div>
          <div v-else>
            <RegionIcon
              :name="briefInfo.bandori_player_brief_info.server"
              :size="2.3"
              class="bandori-brief-info-region"
            />
            <BandoriDegree
              v-for="(item, index) in degreeList"
              :key="index"
              class="bandori-brief-info-degree"
              :setting="{
                ...item,
                server: briefInfo.bandori_player_brief_info.server
              }"
              :scale="0.46"
              :listening-setting="false"
            />
          </div>
        </div>
        <div v-if="isLoading.degreeData || isLoading.cardData">
          <div class="skeleton-animation line-container-lite room-number-skeleton-bar" />
        </div>
        <div
          v-else-if="briefInfo.bandori_player_brief_info.is_valid_power"
          class="line-container-lite flex-container bandori-brief-info-band-power-container"
        >
          <BandoriLabel
            label="综合力"
            :label-style="{
              fontSize: '1.4rem',
              height: '2.4rem',
              lineHeight: '2.4rem',
              paddingLeft: '1.2rem',
              paddingRight: '1.2rem'
            }"
          />
          <span class="bandori-brief-info-band-power">{{ briefInfo.bandori_player_brief_info.band_power }}</span>
        </div>
        <div class="line-container-lite">
          <div v-if="isLoading.degreeData || isLoading.cardData">
            <div
              class="skeleton-animation skeleton-block"
              :style="{
                maxWidth: '24rem',
                height: '4.6rem'
              }"
            />
          </div>
          <div
            v-else
            class="bandori-brief-info-card-icon-container"
          >
            <BandoriCharacterIcon
              v-for="(item, index) in cardList"
              :key="index"
              :size="4.6"
              class="bandori-brief-info-card-icon"
              :image-url="item.cardData.iconURL"
              :attribute="item.cardData.attribute"
              :rarity="item.cardData.rarity"
              :band-id="item.cardData.bandId"
              :is-after-training="item.situationData.isAfterTraining"
              :style="item.style"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ASSETS_URL, BANDORI_DATA_TYPE, BANDORI_DECK_CHARACTER_ORDER } from '@/utilities/constants'
import userAvatarImage from '@/assets/images/user_avatar.png'
import cloneDeep from 'lodash/cloneDeep'
import { getBandoriDataById } from '@/network/common'
import store from '@/store'
import RegionIcon from '@/components/common/RegionIcon'
import BandoriDegree from '@/components/project/Bandori/BandoriDegree'
import BandoriLabel from '@/components/project/Bandori/BandoriLabel'
import BandoriCharacterIcon from '@/components/project/Bandori/BandoriCharacterIcon'
import Button1 from '@/components/common/Button1'

export default defineComponent({
  name: 'UserBriefInfo',
  components: { 
    Button1, 
    BandoriCharacterIcon, 
    BandoriLabel, 
    BandoriDegree, 
    RegionIcon 
  },
  props: {
    briefInfo: {
      type: Object,
      default () {
        return {
          user_id: 0,
          avatar: '',
          username: '',
          role: 0,
          introduction: '',
          bandori_player_brief_info: null
        }
      }
    },
    isFollowing: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'followUser',
    'unfollowUser'
  ],
  data () {
    return {
      isLoading: {
        degreeData: true,
        cardData: true
      },
      displayPlayerBriefData: false
    }
  },
  computed: {
    avatarStyle: function () {
      if (this.briefInfo.avatar) {
        return {
          backgroundImage: 'url("' + ASSETS_URL + '/images/user-avatar/' + this.briefInfo.avatar + '")'
        }
      } else {
        return {
          backgroundImage: 'url("' + userAvatarImage + '")'
        }
      }
    },
    degreeList: function () {
      const response = []
      if (this.displayPlayerBriefData) {
        for (let item of this.briefInfo.bandori_player_brief_info.degrees) {
          response.push(this.$store.state.bandoriData.degreeData[item])
        }
      }
      return response;
    },
    cardList: function () {
      const response = []
      if (this.displayPlayerBriefData) {
        for (let i = 0; i < 5; i++) {
          const situationData = cloneDeep(this.briefInfo.bandori_player_brief_info.main_deck[i])
          situationData.isAfterTraining = situationData.trainingStatus === 'done'
          const cardData = cloneDeep(this.$store.state.bandoriData.cardBriefData[situationData.situationId])
          cardData.iconURL = this.$globalFunctions.getCardImageURL(
            this.briefInfo.bandori_player_brief_info.server,
            cardData.resourceSetName,
            'icon',
            situationData.illust,
            cardData.isLocalExist,
            cardData.situationId
          )
          response.push({
            situationData,
            cardData,
            style: {
              order: BANDORI_DECK_CHARACTER_ORDER[i]
            }
          })
        }
      }
      return response
    }
  },
  created () {
    if (this.$globalFunctions.isEmpty(this.briefInfo.bandori_player_brief_info)) {
      this.isLoading.cardData = false
      this.isLoading.degreeData = false
      return
    }
    this.displayPlayerBriefData = true
    this.isLoading.cardData = true
    this.isLoading.degreeData = true
    const update_card_list = []
    const update_degree_list = []
    for (let item of this.briefInfo.bandori_player_brief_info.degrees) {
      if (!this.$store.state.bandoriData.degreeData[item]) {
        update_degree_list.push(item)
      }
    }
    for (let item of this.briefInfo.bandori_player_brief_info.main_deck) {
      if (!this.$store.state.bandoriData.cardBriefData[item.situationId]) {
        update_card_list.push(item.situationId)
      }
    }

    if (update_card_list.length > 0) {
      getBandoriDataById(
        { 'id': update_card_list, 'data_type': BANDORI_DATA_TYPE.cardList }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            store.commit('bandoriData/updateCardBriefData', responseData)
            this.isLoading.cardData = false
          },
          () => {
            this.isLoading.cardData = false
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    } else {
      this.isLoading.cardData = false
    }

    if (update_degree_list.length > 0) {
      getBandoriDataById(
        { 'id': update_degree_list, 'data_type': BANDORI_DATA_TYPE.degreeList }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            store.commit('bandoriData/updateDegreeData', responseData)
            this.isLoading.degreeData = false
          },
          () => {
            this.isLoading.degreeData = false
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    } else {
      this.isLoading.degreeData = false
    }
  }
})
</script>

<style lang="scss" scoped>
.user-brief-info-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  background-color: white;
  border: 0.1rem solid rgba(200, 200, 200, 0.5);
  border-radius: 0.6rem;
  padding: 1rem 1.2rem;
}

.user-avatar {
  height: 5rem;
  width: 5rem;
  border-radius: 2.5rem;
  background: {
    repeat: no-repeat;
    size: contain;
  };
}

.info-container {
  padding-left: 1rem;
  flex-grow: 1;
}

.user-info-username {
  font: {
    size: 1.8rem;
    weight: bold;
  };
  margin-right: 0.6rem;
}

.room-number-band-power-container {
  max-width: 23.6rem;
  .room-number-band-power {
    line-height: 2.4rem;
    font-weight: bold;
  }
}

.user-brief-info-following-button {
  width: 8rem;
  height: 2.4rem;
  line-height: 2.4rem;
  font-size: 1.6rem;
}
</style>
