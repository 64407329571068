<template>
  <g>
    <path
      d="m 16.09771,20.546565 c 2.369398,-2.729353 5.629944,-3.365386 8.911456,-1.988479 1.111311,0.464794 2.025173,1.055396 2.795751,1.988479 -3.260545,0.817757 -5.55306,5.748763 -5.591502,8.911456 -0.02097,1.822481 -0.134546,3.758539 0.754853,5.416769 1.296529,2.41483 3.826684,4.179648 6.583994,4.193626 1.562126,0.007 2.706637,0.05766 4.193627,-0.583613 1.471264,-0.632538 2.771288,-1.866164 3.788242,-3.08581 2.851667,-3.082316 2.512682,-8.408222 2.545882,-9.032024 -0.02271,-1.385644 0.0664,-3.527889 -0.478773,-4.52737 0.634287,-0.969776 1.104322,-2.369399 1.474759,-3.389848 0.73039,-2.01993 2.327463,-6.52808 2.407841,-8.6091665 C 43.625375,6.628965 42.70103,4.5915616 38.813188,4.8274531 36.573092,4.9253041 34.236893,6.101267 32.348013,7.231799 31.781873,7.570784 29.429948,8.9109975 28.940691,9.0350585 25.6452,7.488659 17.352302,7.520111 15.002124,9.0560295 14.699834,8.8935235 11.03041,6.815931 11.03041,6.815931 8.8916603,5.6085162 6.1797813,4.6544662 3.6915628,4.8361899 0.49217491,5.0703337 0.69311891,7.675625 0.72107691,10.062497 c 0.127556,2.683921 1.09907999,5.844867 2.11253969,8.259698 0,0 1.0169545,2.542386 1.6529878,3.559341 -0.5346873,0.828241 -0.7478633,4.160427 -0.6360333,5.098751 0.094357,5.685859 2.926802,12.019983 8.7524489,12.088129 1.139269,0.01223 2.406094,0.0035 3.49469,-0.366942 3.30947,-1.127037 4.887322,-4.756271 4.892564,-8.020311 0,0 0,-1.572611 0,-1.572611 -0.01223,-2.666447 -1.097332,-5.278727 -2.970485,-7.164112 -0.68496,-0.688454 -0.988998,-1.079859 -1.922079,-1.397875 z"
      style="fill:#22cccc;fill-opacity:1;stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
    />
    <path
      d="m 13.882976,21.298556 c 3.089148,-5.913513 12.511933,-6.575473 16.13065,0.02118 -1.113859,0.211827 -2.130631,0.474845 -3.067966,1.149163 -2.037072,1.466904 -3.500447,5.179177 -3.530455,7.655792 -0.02648,2.280674 0.09885,3.797004 1.646957,5.648727 2.647841,3.168584 7.706983,2.970878 10.517225,0.160636 2.310684,-2.411301 3.105036,-4.30892 3.325689,-9.339818 0,-1.380408 -0.203001,-4.236546 -0.548987,-4.829663 0.37776,-0.496029 1.616949,-3.819952 1.616949,-3.819952 0,0 1.954108,-6.178296 1.954108,-6.178296 0.284201,-1.179173 0.63548,-3.2850892 0.135921,-4.4095388 C 41.484073,6.0575803 40.043647,5.8351618 38.772685,6.0169795 36.327843,6.3647296 33.635872,7.978148 31.535252,9.2614674 31.095708,9.5297827 29.593501,10.361205 29.138072,10.638345 28.740897,10.484769 27.554664,10.059351 27.122182,9.9163663 25.860044,9.4997735 24.387845,9.2967724 23.062159,9.2950069 21.321645,9.291474 19.653505,9.2173422 17.942999,9.6303998 17.145117,9.8228098 15.5723,10.262351 14.76559,10.495363 14.366649,10.239403 12.797361,9.4150412 12.294271,9.1202486 10.364879,7.986975 7.3957655,6.28353 5.090378,6.0628761 2.8714871,5.9075363 1.6817237,5.8863532 1.6923148,8.7989794 c -0.04413,3.2321316 1.1438677,6.3742356 2.3795271,9.3221666 0,0 1.466904,3.417481 1.6275397,3.64343 -0.1800532,0.637246 -0.6354819,3.701682 -0.6425427,4.300094 -0.045897,4.526043 1.9629326,11.452796 7.1050401,11.936468 0.661962,0.06709 2.144752,0.01059 2.67079,-0.109444 5.052081,-1.214477 4.919688,-7.699922 4.919688,-7.699922 -0.01589,-3.523395 -2.008828,-8.568414 -5.869381,-8.893216 z"
      style="fill:#ffffff;fill-opacity:1;stroke:#000000;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"
    />
    <path
      d="M 40.630575,7.5538095 C 38.796585,6.6135425 34.379893,9.4688319 29.241483,12.76884 26.007253,9.9970503 17.886585,10.062397 14.875531,12.736168 9.7767189,9.5595903 5.480612,6.8150284 3.3442572,7.541103 2.061004,9.1511743 5.7505807,18.726284 7.5503741,21.585204 c -2.3397314,5.672459 0.192578,10.431879 0.912495,12.041949 3.6445799,5.881207 9.1951429,2.401493 9.3913219,-1.922282 0.42475,-2.475916 -0.687523,-11.707955 -7.784106,-7.487647 4.119726,-12.787991 20.125288,-12.800697 23.397312,0 -5.92672,-4.087798 -8.675003,4.790278 -8.212457,7.600188 0.359959,4.312884 6.310076,7.552992 10.253424,1.809741 2.111157,-3.432517 2.208345,-9.471644 1.108672,-12.087329 1.533422,-2.581197 5.30759,-13.405155 4.013539,-13.9860145 z"
      style="fill:#22cccc;fill-opacity:1;stroke:#000000;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"
    />
  </g>
</template>
