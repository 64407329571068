<template>
  <div
    v-if="isLoading"
    class="follow-list-container"
  >
    <div
      v-for="index in [1, 2, 3, 4, 5]"
      :key="index"
      class="skeleton-animation skeleton-block line-container"
      :style="{ height: '10rem' }"
    />
  </div>
  <div
    v-else-if="userIdList.length === 0"
    class="no-record-hint"
  >
    <span v-if="followType === 'following'">{{ isSelf ? '您' : '该用户' }}还没有关注任何用户哦</span>
    <span v-else>还没有用户关注{{ isSelf ? '您' : '该用户' }}哦</span>
  </div>
  <div
    v-else
    class="follow-list-container"
  >
    <UserBriefInfo
      v-for="(item, index) in userList"
      :key="index"
      class="line-container"
      :brief-info="item"
      :is-following="item.isFollowing"
      @followUser="$emit('followUser', { userID: item.user_id, index })"
      @unfollowUser="$emit('unfollowUser', { userID: item.user_id, username: item.username, index })"
    />
    <div
      v-if="isAppending"
      class="line-container"
    >
      <Loading />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { getFollowingList, getFollowerList } from '@/network/mainAction'
import cloneDeep from 'lodash/cloneDeep'
import { getUserBriefInfo } from '@/network/common'
import UserBriefInfo from '@/components/project/Account/UserBriefInfo'
import Loading from '@/components/common/Loading'

export default defineComponent({
  name: 'FollowList',
  components: {
    Loading,
    UserBriefInfo
  },
  props: {
    userId: {
      type: Number,
      default: 0
    },
    followType: {
      type: String,
      default: 'following'
    }
  },
  emits: [
    'followUser',
    'unfollowUser'
  ],
  data () {
    return {
      userList: [],
      userIdList: [],
      isLoading: true,
      appendFlag: false,
      isAppending: false,
      isSelf: false
    }
  },
  methods: {
    initializeList () {
      this.isLoading = true
      this.isSelf = this.userId === this.$store.state.account.id
      this.userList = []
      const initializeUserBriefInfoList = () => {
        if (this.userIdList.length === 0) {
          this.isLoading = false
          return
        }
        const queryUserList = []
        let index = 0;
        do {
          queryUserList.push(this.userIdList[index].user_id)
          index += 1
        } while (queryUserList.length < 40 && index < this.userIdList.length)
        this.requestUserInfo(queryUserList, 'loading')
      }
      if (this.followType === 'following') {
        if (this.isSelf) {
          this.userIdList = cloneDeep(this.$store.state.account.followingUser)
          initializeUserBriefInfoList()
        } else {
          getFollowingList(
            this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
            { user_id: this.userId }
          ).then(response => {
            this.$globalFunctions.handleAPIResponse(
              response,
              responseData => {
                this.userIdList = responseData
                initializeUserBriefInfoList()
              }
            )
          }).catch(error => {
            this.$globalFunctions.notify({ content: '请求失败' })
            console.log(error)
          })
        }
      } else {
        getFollowerList(
          this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
          { user_id: this.userId }
        ).then(response => {
          this.$globalFunctions.handleAPIResponse(
            response,
            responseData => {
              this.userIdList = responseData
              initializeUserBriefInfoList()
            }
          )
        }).catch(error => {
          this.$globalFunctions.notify({ content: '请求失败' })
          console.log(error)
        })
      }
    },
    requestUserInfo (user_id, type) {
      getUserBriefInfo(
        { user_id }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            for (let item of responseData) {
              if (this.followType === 'following' && this.isSelf) {
                  item.isFollowing = true
              } else {
                item.isFollowing = this.$store.state.account.followingUser.some(
                  user => user.user_id === item.user_id
                )
              }
              this.userList.push(item)
            }
            if (type === 'loading') {
              this.isLoading = false
            } else if (type === 'appending') {
              this.isAppending = false
            }
            if (responseData.length === 40) {
              this.appendFlag = true
            }
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    },
    checkAppendFollowList ([height, top]) {
      const bottomToTop = top + document.documentElement.clientHeight;
      if (height - bottomToTop < 400 && this.appendFlag) {
        this.appendFlag = false
        this.isAppending = true
        const queryUserList = []
        let index = this.userList.length;
        do {
          queryUserList.push(this.userIdList[index].user_id)
          index += 1
        } while (queryUserList.length < 40 && index < this.userIdList.length)
        this.requestUserInfo(queryUserList, 'appending')
      }
    },
    changeUserFollowingStatus (index, isFollowing) {
      this.userList[index].isFollowing = isFollowing
    },
    removeUserFromList (userID) {
      for (let i = 0; i < this.userIdList.length; i++) {
        if (this.userIdList[i].user_id === userID) {
          this.userIdList.splice(i, 1)
          break
        }
      }
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].user_id === userID) {
          this.userList.splice(i, 1)
          break
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.follow-list-container {
  margin: {
    left: 3rem;
    right: 3rem;
    top: 2rem;
    bottom: 2rem;
  };
  @media screen and (max-width: 700px) {
    margin: {
      left: 1rem;
      right: 1rem;
      top: 0;
      bottom: 1rem;
    };
  }
}
</style>
