<template>
  <div id="user-info-container">
    <div
      class="user-data-banner"
      :class="{ 'skeleton-animation': isLoading }"
      :style="isLoading ? '' : bannerStyle"
    />
    <section class="user-data-sub-container">
      <div id="user-data-avatar-container">
        <div id="user-data-avatar-border">
          <div
            class="user-data-avatar"
            :class="{ 'skeleton-animation': isLoading }"
            :style="isLoading ? '' : avatarStyle"
          />
        </div>
        <div
          v-if="isSelf"
          class="account-setting-button-container"
        >
          <div
            v-if="isLoading"
            class="skeleton-animation skeleton-block button-1"
            :style="{ width: '12rem' }"
          />
          <Button1
            v-else
            class="button-1"
            color="1"
            :config="{
              type: 'link',
              path: '/account-setting'
            }"
            :style="{ width: '12rem' }"
          >
            编辑资料
          </Button1>
        </div>
        <div
          v-else
          class="account-setting-button-container"
        >
          <template v-if="$store.state.account.role > 0 && $store.state.account.role > summary.role">
            <div
              v-if="isLoading"
              class="skeleton-animation skeleton-block button-1 account-setting-button"
            />
            <Button1
              v-else-if="!summary.ban_status.is_banned"
              class="button-1 account-setting-button"
              color="2"
              :config="{ type: 'button' }"
              @click="switchDialog('banSetting', true)"
            >
              封禁
            </Button1>
            <Button1
              v-else
              class="button-1 account-setting-button"
              color="1"
              :config="{ type: 'button' }"
              @click="confirmLiftBanUser"
            >
              解封
            </Button1>
          </template>
          <div
            v-if="isLoading"
            class="skeleton-animation skeleton-block button-1 account-setting-button"
          />
          <Button1
            v-else-if="isFollowing"
            class="button-1 account-setting-button"
            color="2"
            :config="{ type: 'button' }"
            @click="unfollowUser(summary.username)"
          >
            取消关注
          </Button1>
          <Button1
            v-else
            class="button-1 account-setting-button"
            color="1"
            :config="{ type: 'button' }"
            @click="followUser"
          >
            关注
          </Button1>
        </div>
      </div>
      <div class="user-data-line-container">
        <div
          v-if="isLoading"
          class="skeleton-animation"
          :style="{
            width: '15rem',
            height: '3rem',
            borderRadius: '1.5rem'
          }"
        />
        <div
          v-else
          id="username"
        >
          {{ summary.username }}
        </div>
        <div
          v-if="isLoading"
          class="skeleton-animation user-data-detail"
          :style="{
            width: '6rem',
            height: '1.8rem',
            borderRadius: '0.9rem'
          }"
        />
        <div
          v-else
          class="user-data-detail"
        >
          UID: {{ summary.user_id }}
        </div>
        <div
          v-if="isLoading"
          class="skeleton-animation user-data-detail"
          :style="{
            width: '12rem',
            height: '1.8rem',
            borderRadius: '0.9rem'
          }"
        />
        <div
          v-else
          class="user-data-detail"
        >
          <span
            class="user-following-summary"
            :style="{ marginRight: '1rem' }"
            @click="switchDialog('followingList', true)"
          >
            <span class="user-data-bold">{{ summary.following }}</span> 关注
          </span>
          <span
            class="user-following-summary"
            @click="switchDialog('followerList', true)"
          >
            <span class="user-data-bold">{{ summary.follower }}</span> 关注者
          </span>
        </div>
        <div
          v-if="isLoading"
          class="skeleton-animation user-data-detail"
          :style="{
            width: '12rem',
            height: '1.8rem',
            borderRadius: '0.9rem'
          }"
        />
        <div
          v-else-if="summary.introduction"
          class="user-data-detail word-wrap"
        >
          {{ summary.introduction }}
        </div>
      </div>
    </section>
    <section
      v-if="!isLoading && summary.ban_status.is_banned"
      class="line-container"
    >
      <div class="banned-user-notice">
        <font-awesome-icon icon="exclamation-triangle" />
        <span class="banned-user-notice-text">{{ isSelf ? '您' : '该用户' }}已被封禁</span>
        <span v-if="summary.ban_status.interval > 0">剩余{{ banIntervalDescription }}</span>
      </div>
    </section>
    <section
      id="user-profile-container"
      class="user-data-sub-container"
    >
      <div
        v-if="isLoading"
        class="skeleton-animation skeleton-block"
        :style="{
          width: '100%',
          height: '3.4rem',
        }"
      />
      <div
        v-if="isLoading"
        class="skeleton-animation skeleton-block line-container"
        :style="{
          width: 'calc(100% - 2rem)',
          height: '10rem',
          marginLeft: '1rem'
        }"
      />
      <div
        v-if="isLoading"
        class="skeleton-animation skeleton-block line-container"
        :style="{
          width: 'calc(100% - 2rem)',
          height: '10rem',
          marginLeft: '1rem',
          marginBottom: '1rem'
        }"
      />
      <Tabs1
        v-else
        v-model="activeTab"
      >
        <Tabs1Pane
          label="发布历史"
        >
          <RoomNumberHistoryList
            :room-number-list="roomNumberHistory"
            :user-info="{
              user_id: summary.user_id,
              username: summary.username,
              avatar: summary.avatar,
              type: 'local'
            }"
            :is-loading="isAppendingRoomNumberHistory"
          />
        </Tabs1Pane>
        <Tabs1Pane
          label="玩家信息"
        >
          <BandoriProfile
            :profile="bandoriProfile"
            :is-self="isSelf"
            :main-account="summary.main_game_account"
            :is-synchronizing="isSynchronizing"
            @syncProfile="syncBandoriProfileData($event)"
          />
        </Tabs1Pane>
      </Tabs1>
    </section>
    <div>
      <Modal2
        :display-modal="dialogOpenStatus.followingList"
        title="关注列表"
        :content-padding="false"
        @hideModalEvent="switchDialog('followingList', false)"
        @modalScroll="$refs.followingList.checkAppendFollowList($event)"
      >
        <FollowList
          ref="followingList"
          :user-id="summary.user_id"
          follow-type="following"
          @unfollowUser="unfollowUserFromList($event, 'following')"
        />
      </Modal2>
      <Modal2
        :display-modal="dialogOpenStatus.followerList"
        title="关注者列表"
        :content-padding="false"
        @hideModalEvent="switchDialog('followerList', false)"
        @modalScroll="$refs.followerList.checkAppendFollowList($event)"
      >
        <FollowList
          ref="followerList"
          :user-id="summary.user_id"
          follow-type="follower"
          @followUser="followUserFromList($event)"
          @unfollowUser="unfollowUserFromList($event, 'follower')"
        />
      </Modal2>
      <Modal2
        :display-modal="dialogOpenStatus.banSetting"
        title="封禁用户"
        @hideModalEvent="switchDialog('banSetting', false)"
      >
        <BanSetting
          :user-id="summary.user_id"
          @updateBanStatus="updateBanStatus($event)"
          @hideModalEvent="switchDialog('banSetting', false)"
        />
      </Modal2>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Button1 from '@/components/common/Button1'
import BandoriProfile from '@/components/project/Bandori/BandoriProfile'
import Tabs1 from '@/components/common/Tabs1'
import Tabs1Pane from '@/components/common/Tabs1Pane'
import RoomNumberHistoryList from '@/components/project/Account/RoomNumberHistoryList'
import { followUser, getUserInfo, getUserRoomNumberHistory, liftBanUser } from '@/network/mainAction'
import { ASSETS_URL } from '@/utilities/constants'
import userAvatarImage from '@/assets/images/user_avatar.png'
import userBannerImage from '@/assets/images/user_banner.png'
import { syncGameAccountData } from '@/network/accountManage'
import Modal2 from '@/components/common/Modal2'
import FollowList from '@/components/project/Account/FollowList'
import BanSetting from '@/components/project/Account/BanSetting'

export default defineComponent({
  name: 'UserInfo',
  components: {
    BanSetting,
    FollowList,
    Modal2,
    RoomNumberHistoryList,
    Tabs1Pane,
    Tabs1,
    BandoriProfile,
    Button1
  },
  data () {
    return {
      summary: {
        avatar: '',
        role: 0,
        user_id: 0,
        username: '',
        introduction: '',
        main_game_server: '',
        following: 0,
        follower: 0,
        ban_status: {
          interval: 0,
          is_banned: false
        }
      },
      isSelf: false,
      roomNumberHistory: [],
      roomNumberHistoryAppendFlag: true,
      isAppendingRoomNumberHistory: false,
      bandoriProfile: {
        cn: [],
        jp: []
      },
      isLoading: true,
      activeTab: 0,
      isSynchronizing: false,
      dialogOpenStatus: {
        followingList: false,
        followerList: false,
        banSetting: false
      }
    }
  },
  computed: {
    avatarStyle () {
      if (this.summary.avatar) {
        return {
          backgroundImage: 'url("' + ASSETS_URL + '/images/user-avatar/' + this.summary.avatar + '")'
        }
      } else {
        return {
          backgroundImage: 'url("' + userAvatarImage + '")'
        }
      }
    },
    bannerStyle () {
      if (this.summary.banner) {
        return {
          backgroundImage: 'url("' + ASSETS_URL + '/images/user-banner/' + this.summary.banner + '")'
        }
      } else {
        return {
          backgroundImage: 'url("' + userBannerImage + '")'
        }
      }
    },
    isFollowing () {
      for (let user of this.$store.state.account.followingUser) {
        if (this.summary.user_id === user.user_id) {
          return true
        }
      }
      return false
    },
    banIntervalDescription () {
      return this.$globalFunctions.getTimeDifferenceDescription(
        this.summary.ban_status.interval, { mode: 'limit', value: 1 }
      )
    }
  },
  methods: {
    initialize (userID) {
      this.isLoading = true
      this.isSelf = userID === this.$store.state.account.id
      getUserInfo(
        this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
        { user_id: userID }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            this.summary = responseData.summary
            this.roomNumberHistory = responseData.room_number_history
            this.roomNumberHistoryAppendFlag = responseData.room_number_history_append_flag
            this.bandoriProfile = responseData.bandori_profile
            this.isLoading = false
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    },
    syncBandoriProfileData (account) {
      if (this.isSynchronizing) {
        return
      }
      this.isSynchronizing = true
      syncGameAccountData(
        this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
        { server: account.server, player_id: account.playerID }
      ).then(response => {
        this.isSynchronizing = false
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            let index;
            for (index = 0; index < this.bandoriProfile[account.server].length; index++) {
              if (this.bandoriProfile[account.server][index].userId === account.playerID) {
                break
              }
            }
            this.bandoriProfile[account.server][index] = responseData
            this.$globalFunctions.notify({ content: '数据更新成功' })
          }
        )
      }).catch(error => {
        this.isSynchronizing = false
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    },
    followUser (userID = null, callback = null) {
      if (userID === null) {
        userID = this.summary.user_id
      }
      followUser(
        this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
        { user_id: userID, is_following: true }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            this.$store.commit('account/appendFollowingUser', responseData)
            // this.$globalFunctions.notify({ content: '关注成功' })
            this.summary.follower += 1
            if (callback !== null) {
              callback()
            }
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    },
    unfollowUser (username, userID = null, callback = null) {
      if (userID === null) {
        userID = this.summary.user_id
      }
      this.$globalFunctions.notify({
        content: '确认取消关注用户“' + username + '”？',
        displayCancel: true,
        callback: () => {
          followUser(
            this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
            { user_id: userID, is_following: false }
          ).then(response => {
            this.$globalFunctions.handleAPIResponse(
              response,
              responseData => {
                this.$store.commit('account/removeFollowingUser', responseData.user_id)
                // this.$globalFunctions.notify({ content: '取消关注成功' })
                this.summary.follower -= 1
                if (callback !== null) {
                  callback()
                }
              }
            )
          }).catch(error => {
            this.$globalFunctions.notify({ content: '请求失败' })
            console.log(error)
          })
        }
      })
    },
    followUserFromList (params) {
      this.followUser(params.userID, () => {
        this.$refs.followerList.changeUserFollowingStatus(params.index, true)
      })
    },
    unfollowUserFromList (params, type) {
      this.unfollowUser(params.username, params.userID, () => {
        if (type === 'following') {
          this.$refs.followingList.removeUserFromList(params.userID)
        } else {
          this.$refs.followerList.changeUserFollowingStatus(params.index, false)
        }
      })
    },
    checkUpdateRoomNumberHistory ([height, top]) {
      const bottomToTop = top + document.documentElement.clientHeight;
      if (height - bottomToTop < 400 && this.roomNumberHistoryAppendFlag) {
        this.roomNumberHistoryAppendFlag = false
        this.isAppendingRoomNumberHistory = true
        getUserRoomNumberHistory(
          this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
          {
            user_id: this.summary.user_id,
            start_time: this.roomNumberHistory[this.roomNumberHistory.length - 1].time
          }
        ).then(response => {
          this.$globalFunctions.handleAPIResponse(
            response,
            responseData => {
              this.isAppendingRoomNumberHistory = false
              for (let item of responseData.room_number_history) {
                this.roomNumberHistory.push(item)
              }
              this.roomNumberHistoryAppendFlag = responseData.room_number_history_append_flag
            }
          )
        }).catch(error => {
          this.$globalFunctions.notify({ content: '请求失败' })
          console.log(error)
          this.roomNumberHistoryAppendFlag = true
          this.isAppendingRoomNumberHistory = false
        })
      }
    },
    confirmLiftBanUser () {
      liftBanUser(
        this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
        { user_id: this.summary.user_id }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          () => {
            this.summary.ban_status = {
              interval: 0,
              is_banned: false
            }
            this.$globalFunctions.notify({ content: '解封成功' })
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    },
    updateBanStatus ({ interval, isPermanent }) {
      if (isPermanent) {
        this.summary.ban_status.interval = 0
      } else {
        this.summary.ban_status.interval = interval
      }
      this.summary.ban_status.is_banned = true
    },
    switchDialog (name, status) {
      if ((name === 'followingList' || name === 'followerList') && status) {
        this.$refs[name].initializeList()
      }
      this.dialogOpenStatus[name] = status
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/constants";

#user-info-container {
  width: 100%;
}

.user-data-banner {
  width: 100%;
  height: 15rem;
  background: {
    repeat: no-repeat;
    size: cover;
    position: center;
  }
  border: {
    top: {
      left-radius: 0.6rem;
      right-radius: 0.6rem;
    };
  };
}

.user-data-sub-container {
  padding: {
    left: 1.6rem;
    right: 1.6rem;
    top: 1.2rem;
  };
}

#user-data-avatar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

#user-data-avatar-border {
  position: relative;
  width: 13rem;
  height: 13rem;
  background-color: white;
  border-radius: 6.5rem;
  margin: {
    top: -9rem;
    bottom: -0.5rem;
    left: 4rem;
  };
  @media screen and (max-width: 700px) {
    margin-left: 0;
  }
  @media screen and (max-width: 550px) {
    transform: scale(0.7);
    margin: {
      left: -2rem;
      bottom: -2.5rem;
    };
  }
}

.user-data-avatar {
  width: 12rem;
  height: 12rem;
  margin: 0.5rem;
  background: {
    repeat: no-repeat;
    size: contain;
  };
  border-radius: 6rem;
}

.account-setting-button-container {
  display: flex;
}

.account-setting-button {
  width: 12rem;
  margin-left: 0.5rem;
  @media screen and (max-width: 550px) {
    width: 9rem;
  }
}

.user-data-line-container {
  margin-top: 1rem;
  padding-left: 6rem;
  @media screen and (max-width: 700px) {
    padding-left: 2rem;
  }
  @media screen and (max-width: 550px) {
    padding-left: 1rem;
  }
}

#username {
  font: {
    weight: bold;
    size: 2.4rem;
  };
  @media screen and (max-width: 550px) {
    font-size: 2rem;
  }
}

.user-data-detail {
  margin-top: 0.5rem;
}

.user-data-bold {
  font-weight: bold;
}

.user-following-summary {
  user-select: none;
  cursor: pointer;
}

#user-profile-container {
  padding: {
    left: 0;
    right: 0;
    top: 1.6rem;
  };
}

.banned-user-notice {
  background-color: $font_color;
  color: white;
  text-align: center;
  height: 3rem;
  line-height: 3rem;
  user-select: none;
}

.banned-user-notice-text {
  margin: 0 0.6rem;
}
</style>
