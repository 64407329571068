<template>
  <div class="room-number-history-list">
    <div
      v-if="roomNumberList.length === 0"
      class="no-record-hint"
    >
      <span>没有找到近期的发布记录哦</span>
    </div>
    <div
      v-else
      class="room-number-history-container"
    >
      <RoomNumberMessage
        v-for="(message, index) in roomNumberListProcessed"
        :key="index"
        :message="message"
        :is-history="true"
      />
      <div
        v-if="isLoading"
        class="line-container"
      >
        <Loading />
      </div>
    </div>
  </div>
</template>

<script>
import RoomNumberMessage from '@/components/project/RoomNumber/RoomNumberMessage'
import Loading from '@/components/common/Loading'
import { defineComponent } from 'vue'
import { ROOM_NUMBER_TYPE } from '@/utilities/constants'

export default defineComponent({
  name: 'RoomNumberHistoryList',
  components: {
    Loading,
    RoomNumberMessage
  },
  props: {
    roomNumberList: {
      type: Array,
      default () {
        return []
      }
    },
    userInfo: {
      type: Object,
      default () {
        return {
          username: '',
          avatar: ''
        }
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    roomNumberListProcessed: function () {
      const response = []
      for (let item of this.roomNumberList) {
        response.push({
          number: item.number,
          raw_message: item.raw_message,
          type: ROOM_NUMBER_TYPE[item.type],
          source_info: {
            name: item.source_name === 'BandoriStation' || item.source_name === 'Bandori Station'
              ? '本站' : item.source_name
          },
          user_info: this.userInfo,
          time: new Date(item.time).toLocaleString()
        })
      }
      return response
    }
  }
})
</script>

<style lang="scss" scoped>
.room-number-history-container {
  margin: {
    left: 3rem;
    right: 3rem;
    top: 2rem;
    bottom: 2rem;
  };
  @media screen and (max-width: 700px) {
    margin: {
      left: 1rem;
      right: 1rem;
      top: 0;
      bottom: 1rem;
    };
  }
}
</style>
