<template>
  <IconBase
    v-if="bandId === 1"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="0 0 66.145832 66.145835"
  >
    <IconBandoriBand1 />
  </IconBase>
  <IconBase
    v-else-if="bandId === 2"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="0 0 66.145832 66.145835"
  >
    <IconBandoriBand2 />
  </IconBase>
  <IconBase
    v-else-if="bandId === 3"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="0 0 66.145832 66.145835"
  >
    <IconBandoriBand3 />
  </IconBase>
  <IconBase
    v-else-if="bandId === 4"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="0 0 250 250"
  >
    <IconBandoriBand4 />
  </IconBase>
  <IconBase
    v-else-if="bandId === 5"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="0 0 66.145832 66.145835"
  >
    <IconBandoriBand5 />
  </IconBase>
  <IconBase
    v-else-if="bandId === 18"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="0 0 44 44"
  >
    <IconBandoriBand18 />
  </IconBase>
  <IconBase
    v-else-if="bandId === 21"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="0 0 44 44"
  >
    <IconBandoriBand21 />
  </IconBase>
  <font-awesome-icon
    v-else
    :icon="['far', 'circle']"
    :style="{ fontSize: size + 'rem' }"
  />
</template>

<script>
import IconBase from '@/components/common/IconBase'
import IconBandoriBand1 from '@/components/icons/IconBandoriBand1'
import IconBandoriBand2 from '@/components/icons/IconBandoriBand2'
import IconBandoriBand3 from '@/components/icons/IconBandoriBand3'
import IconBandoriBand4 from '@/components/icons/IconBandoriBand4'
import IconBandoriBand5 from '@/components/icons/IconBandoriBand5'
import IconBandoriBand18 from '@/components/icons/IconBandoriBand18'
import IconBandoriBand21 from '@/components/icons/IconBandoriBand21'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BandoriBandLogo',
  components: {
    IconBandoriBand1,
    IconBandoriBand2,
    IconBandoriBand3,
    IconBandoriBand4,
    IconBandoriBand5,
    IconBandoriBand18,
    IconBandoriBand21,
    IconBase
  },
  props: {
    bandId: {
      type: Number,
      default: 0,
      require: true
    },
    size: {
      type: Number,
      default: 3
    }
  }
})
</script>

<style lang="scss" scoped>

</style>