<template>
  <div class="bandori-user-rank-container">
    <div class="bandori-user-rank-label">
      等级
    </div>
    <div class="bandori-user-rank">
      {{ rank }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BandoriUserRank',
  props: {
    rank: {
      type: Number,
      default: 0
    }
  }
})
</script>

<style lang="scss" scoped>
.bandori-user-rank-container {
  display: inline-block;
  width: 6rem;
  height: 6rem;
  border: {
    width: 0.1rem;
    style: solid;
    color: rgb(200, 200, 200);
    radius: 0.6rem;
  }
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  user-select: none;
}

.bandori-user-rank-label {
  margin-top: 0.8rem;
}

.bandori-user-rank {
  font-size: 2.4rem;
}
</style>
