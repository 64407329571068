<template>
  <IconBase
    v-if="name === 'cn'"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="-49 141 512 512"
  >
    <IconRegionCn />
  </IconBase>
  <IconBase
    v-else-if="name === 'jp'"
    :width="size + 'rem'"
    :height="size + 'rem'"
    view-box="0 0 512 512"
  >
    <IconRegionJp />
  </IconBase>
</template>

<script>
import { defineComponent } from 'vue'
import IconBase from '@/components/common/IconBase'
import IconRegionCn from '@/components/icons/IconRegionCn'
import IconRegionJp from '@/components/icons/IconRegionJp'

export default defineComponent({
  name: 'RegionIcon',
  components: {
    IconRegionJp,
    IconRegionCn,
    IconBase
  },
  props: {
    name: {
      type: String,
      default: 'cn'
    },
    size: {
      type: Number,
      default: 3
    }
  }
})
</script>

<style scoped>

</style>
