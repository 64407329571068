import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueCookieNext } from 'vue-cookie-next'
import './registerServiceWorker'
import * as globalFunctions from './utilities/globalFunctions'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faStar, faPen, faTimes, faImage, faCopy, faPlus, faFilter, faComment,
  faExpand, faCompress, faInfo, faSyncAlt, faChartLine, faMinus,
  faExclamationTriangle, faCaretUp
} from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faStar, faPen, faTimes, faImage, faCopy, faPlus, faFilter, faComment,
  faExpand, faCompress, faInfo, faCircle, faSyncAlt, faChartLine, faMinus,
  faExclamationTriangle, faCaretUp
)

router.beforeEach((to, from, next) => {
  store.commit('route/updatePage', { from, to })
  next()
})

const app = createApp(App)
app.config.globalProperties.$globalFunctions = globalFunctions
app
  .use(store)
  .use(router)
  .use(VueCookieNext)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
