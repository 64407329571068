<template>
  <g>
    <circle
      style="fill:#D80027;"
      cx="207"
      cy="397"
      r="256"
    />
    <g>
      <polygon
        style="fill:#FFDA44;"
        points="91.1,296.8 113.2,364.8 184.7,364.8 126.9,406.9 149,474.9 91.1,432.9 33.2,474.9 55.4,406.9 -2.5,364.8 69,364.8"
      />
      <polygon
        style="fill:#FFDA44;"
        points="254.5,537.5 237.6,516.7 212.6,526.4 227.1,503.9 210.2,483 236.1,489.9 250.7,467.4 252.1,494.2 278.1,501.1 253,510.7"
      />
      <polygon
        style="fill:#FFDA44;"
        points="288.1,476.5 296.1,450.9 274.2,435.4 301,435 308.9,409.4 317.6,434.8 344.4,434.5 322.9,450.5 331.5,475.9 309.6,460.4"
      />
      <polygon
        style="fill:#FFDA44;"
        points="333.4,328.9 321.6,353 340.8,371.7 314.3,367.9 302.5,391.9 297.9,365.5 271.3,361.7 295.1,349.2 290.5,322.7 309.7,341.4"
      />
      <polygon
        style="fill:#FFDA44;"
        points="255.2,255.9 253.2,282.6 278.1,292.7 252,299.1 250.1,325.9 236,303.1 209.9,309.5 227.2,289 213,266.3 237.9,276.4"
      />
    </g>
  </g>
</template>
