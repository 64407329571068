<template>
  <div class="drawer-1-container">
    <div
      class="drawer-1-header"
      :class="{ 'drawer-1-header-extend': !isCollapsed }"
      @click="isCollapsed = !isCollapsed"
    >
      <span>{{ (isCollapsed ? '展开' : '收起') + title }}</span>
      <font-awesome-icon
        class="drawer-1-header-icon"
        :class="{ 'drawer-1-header-icon-extend': !isCollapsed }"
        icon="caret-up"
      />
    </div>
    <transition name="fade">
      <div
        v-if="!isCollapsed"
        class="drawer-1-content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Drawer1',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isCollapsed: true
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/styles/constants";

.drawer-1-header {
  background-color: $button_color_1;
  color: white;
  user-select: none;
  cursor: pointer;
  text-align: center;
  height: 3rem;
  line-height: 3rem;
  border-radius: 1.5rem;
  transition: 0.3s;
}

.drawer-1-header-extend {
  background-color: $radio-color_1_checked;
}

.drawer-1-header-icon {
  margin-left: 0.5rem;
  transform: rotate(180deg);
  transition: 0.3s;
}

.drawer-1-header-icon-extend {
  transform: rotate(0);
}

.drawer-1-content {

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
