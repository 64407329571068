<template>
  <div>
    <div class="tab-1-tabs-container">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="active === index ? 'tab-1-active-tab' : ''"
        class="tab-1-tab"
        @click="selectTab(index)"
      >
        {{ tab.props.label }}
      </div>
    </div>
    <div class="tab-1-pane-container">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent, provide, computed, ref } from 'vue'

export default defineComponent({
  name: 'Tabs1',
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
  },
  setup (props, { emit }) {
    const active = computed(() => props.modelValue);
    const tabs = ref([]);

    function selectTab(index) {
      emit("update:modelValue", index);
    }

    provide("tabs1State", { active, tabs });

    return {
      tabs,
      active,
      selectTab,
    };
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/styles/constants";

.tab-1-tabs-container {
  display: flex;
  justify-content: space-around;
  user-select: none;
  cursor: pointer;
  border: {
    bottom: {
      width: 0.1rem;
      style: solid;
      color: $theme-color;
    };
  };
}

.tab-1-tab {
  flex-grow: 1;
  text-align: center;
  font: {
    size: 1.6rem;
    weight: bold;
  };
  height: 3.4rem;
  line-height: 3.4rem;
  transition: 0.25s;
  border: {
    top-left-radius: 0.6rem;
    top-right-radius: 0.6rem;
  };
  &:hover {
    background-color: $tab_inactive_color_hover;
  }
}

.tab-1-active-tab {
  background-color: $theme-color;
  color: white;
  &:hover {
    background-color: $button_color_1_hover;
  }
}
</style>