<template>
  <g>
    <circle
      style="fill:#F0F0F0;"
      cx="256"
      cy="256"
      r="256"
    />
    <circle
      style="fill:#D80027;"
      cx="256"
      cy="256"
      r="111.304"
    />
  </g>
</template>
