<template>
  <div class="bandori-high-score-rating-container">
    <div class="bandori-high-score-rating-summary-container">
      <div class="bandori-high-score-rating-summary">
        {{ processedRatingData.sum }}
      </div>
      <Button1
        class="button-2 bandori-high-score-rating-detail-button"
        color="1"
        shape="circle"
        :config="{ type: 'button' }"
        @click="switchHighScoreRatingDialog(true)"
      >
        <font-awesome-icon icon="info" />
      </Button1>
    </div>
    <div class="line-container table bandori-high-score-rating-band-summary">
      <BandoriHighScoreRatingBandSummary
        v-for="(bandRatingData, index) in processedRatingData.detailList"
        :key="index"
        :band-id="bandRatingData.bandId"
        :music-list="bandRatingData.musicList"
      />
    </div>
    <div>
      <Modal2
        :display-modal="displayHighScoreRating"
        style-type="medium"
        @hideModalEvent="switchHighScoreRatingDialog(false)"
      >
        <div class="table">
          <BandoriHighScoreRatingBandSummary
            v-for="(bandRatingData, index) in processedRatingData.detailList"
            :key="index"
            :band-id="bandRatingData.bandId"
            :music-list="bandRatingData.musicList"
          />
        </div>
      </Modal2>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Button1 from '@/components/common/Button1'
import BandoriHighScoreRatingBandSummary from '@/components/project/Bandori/BandoriHighScoreRatingBandSummary'
import Modal2 from '@/components/common/Modal2'
import { getMusicJacketImageURL } from '@/utilities/globalFunctions'

export default defineComponent({
  name: 'BandoriHighScoreRating',
  components: { Modal2, BandoriHighScoreRatingBandSummary, Button1 },
  props: {
    ratingData: {
      type: Object,
      default () {
        return {}
      }
    },
    bandSettingList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      displayHighScoreRating: false
    }
  },
  computed: {
    processedRatingData: function () {
      const response = {
        sum: 0,
        detailList: []
      }
      for (let bandSetting of this.bandSettingList) {
        const highScoreRatingData = this.ratingData['user' + bandSetting.name + 'HighScoreMusicList']
        if (highScoreRatingData) {
          const bandRatingData = {
            bandId: bandSetting.id,
            musicList: []
          }
          for (let item of highScoreRatingData) {
            response.sum += item.rating
            bandRatingData.musicList.push({
              ...item,
              musicImage: getMusicJacketImageURL(this.$store.state.bandoriData.songData[item.musicId])
            })
          }
          response.detailList.push(bandRatingData)
        }
      }
      return response
    }
  },
  methods: {
    switchHighScoreRatingDialog (isOpen) {
      this.displayHighScoreRating = isOpen
    }
  }
})
</script>

<style lang="scss" scoped>
.bandori-high-score-rating-summary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bandori-high-score-rating-summary {
  width: 7.8rem;
  text-align: center;
  font-weight: bold;
  line-height: 2rem;
}

.bandori-high-score-rating-detail-button {
  height: 2.6rem;
  width: 2.6rem;
  line-height: 2.6rem;
  font-size: 1.6rem;
  margin-right: 0.6rem;
  @media screen and (max-width: 699px) {
    display: none;
  }
}

@media screen and (min-width: 700px) {
  .bandori-high-score-rating-band-summary {
    display: none;
  }
}
</style>
