import { request } from '@/network/request'

const functionGroup = 'MainAction'

export function initializeAccountSetting (headers) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'initializeAccountSetting'
    }
  })
}

export function getRoomNumberFilter (headers) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'getRoomNumberFilter'
    }
  })
}

export function updateRoomNumberFilter (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'updateRoomNumberFilter',
      ...data
    }
  })
}

export function informUser (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'informUser',
      ...data
    }
  })
}

export function getUserInfo (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'getUserInfo',
      ...data
    }
  })
}

export function getUserRoomNumberHistory (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'getUserRoomNumberHistory',
      ...data
    }
  })
}

export function followUser (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'followUser',
      ...data
    }
  })
}

export function getFollowingList (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'getFollowingList',
      ...data
    }
  })
}

export function getFollowerList (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'getFollowerList',
      ...data
    }
  })
}

export function banUser (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'banUser',
      ...data
    }
  })
}

export function liftBanUser (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'liftBanUser',
      ...data
    }
  })
}
