const IS_DEV = false

export const SERVER_URL = IS_DEV
  ? 'http://server.test.bandoristation.com'
  : 'https://server.bandoristation.com'
export const API_URL = IS_DEV
  ? 'http://api.test.bandoristation.com'
  : 'https://api.bandoristation.com'
export const WEBSOCKET_API_URL = IS_DEV
  ? 'ws://api.test.bandoristation.com'
  : 'wss://api.bandoristation.com'
export const ASSETS_URL = IS_DEV
  ? 'http://asset.test.bandoristation.com'
  : 'https://asset.bandoristation.com'

export const ERROR_CODE_DEFINITION = {
  'Not allowed': '不允许的操作',
  'No permission': '无权执行此操作',
  'Unparsable format': '无法解析的上报格式',
  'Missing Parameters': '缺少上报参数',
  'Missing Parameter "function"': '缺少功能参数',
  'Undefined function group': '缺少功能组参数',
  'Undefined function': '功能未定义',
  'Forbidden method': '禁止的上报方法',
  'Undefined access token': '访问令牌未定义',
  'Token validation failure': '令牌校验失败，请重新登陆',
  'Nonexistent user': '用户不存在',
  'Undefined email': '未找到邮箱，请重新设置邮箱地址',
  'Duplicate email': '邮箱已被注册',
  'Invalid email': '无效的邮箱地址',
  'Verified email': '邮箱已被验证',
  'Undefined verification code': '验证码未定义',
  'Invalid verification code': '验证码错误，请重新输入',
  'Too many logins': '登陆次数过多，请稍后再试',
  'Wrong password': '密码错误',
  'Too many signups': '注册次数过多，请稍后再试',
  'Username or email already exists': '用户名或邮箱已被注册',
  'Username already exists': '用户名已经存在',
  'QQ already exists': '该QQ号已经被绑定',
  'Undefined verification request': '未找到验证请求，请重新请求验证',
  'Duplicate number submit': '两次提交间隔过短，请稍后再试',
  'Player verification failure': '游戏账号验证失败，请将游戏内个人介绍修改为指定内容',
  'No player data': '玩家信息获取失败',
  'API request failure': '接口请求失败',
  'Requests are too frequent': '请勿频繁请求，请稍后再试'
}

export const BANNED_ROOM_NUMBER_PATTERN = [
  /^(\d)\1+$/, /^114514$/, /^11451$/, /^14514$/, /^415411$/, /^15411$/
]

export const BANNED_ROOM_NUMBER_DESCRIPTION_WORD = 'WyJcdTkxY2VcdTUxN2QiLCJcdTUxN2RcdTkxY2UiLCJcdTdjYWEiLCJcdTgxZWQiLCJubXNsIiwiXHU0ZjYwXHU1OTg4XHU2YjdiXHU0ZTg2IiwiXHU2NzQwIiwiXHU4MWVhXHU2MTcwIiwiXHU2NjBmXHU3NzYxI\n' +
  'iwiXHU5NmMwIiwiXHU3MzFkXHU2YjdiIiwiXHU4YzAzXHU2MjBmIiwiXHU5MWNlXHU3Mzc4IiwiMTkxOSIsIlx1NGY2MFx1OWE2YyIsIlx1NWI2NFx1NTEzZiIsIlx1NzAzNSIsIlx1N2NkZSIsIlx1NGY2MFx1NT\n' +
  'QxNyIsIlx1NjcwOVx1NzVjNSJd'

export const ROOM_NUMBER_TYPE = {
  25: '25万首席房',
  18: '18万大师房',
  12: '12万高手房',
  7: '7万常规房',
  other: ''
}

export const ROOM_NUMBER_TYPE_LIST = [
  {
    value: 'other',
    briefDescription: '其他',
    fullDescription: '其他房'
  },
  {
    value: '7',
    briefDescription: '7万',
    fullDescription: '7万常规房'
  },
  {
    value: '12',
    briefDescription: '12万',
    fullDescription: '12万高手房'
  },
  {
    value: '18',
    briefDescription: '18万',
    fullDescription: '18万大师房'
  },
  {
    value: '25',
    briefDescription: '25万',
    fullDescription: '25万首席房'
  }
]

export const SERVER_DESCRIPTION = {
  jp: '日服',
  en: '国际服',
  cn: '国服',
  tw: '台服',
  kr: '韩服'
}
export const SERVER_ID = {
  jp: 0,
  en: 1,
  tw: 2,
  cn: 3,
  kr: 4
}

export const BANDORI_CHARACTER_LOCAL_IMAGE_URL = {
  icon: 'https://image.mabo.ai/theme/bandori/character/icon',
  card: 'https://image.mabo.ai/theme/bandori/character/card',
  trim: 'https://image.mabo.ai/theme/bandori/character/trim'
}
export const BANDORI_CHARACTER_REMOTE_IMAGE_URL = {
  jp: {
    icon: 'https://bestdori.com/assets/jp/thumb/chara',
    card: 'https://bestdori.com/assets/jp/characters/resourceset',
    trim: 'https://bestdori.com/assets/jp/characters/resourceset'
  },
  cn: {
    icon: 'https://bestdori.com/assets/cn/thumb/chara',
    card: 'https://bestdori.com/assets/cn/characters/resourceset',
    trim: 'https://bestdori.com/assets/cn/characters/resourceset'
  }
}
export const BANDORI_DEGREE_REMOTE_IMAGE_URL = {
  jp: 'https://bestdori.com/assets/jp/thumb/degree_rip',
  cn: 'https://bestdori.com/assets/cn/thumb/degree_rip'
}
export const BANDORI_SONG_REMOTE_IMAGE_URL =
  'https://bestdori.com/assets/jp/musicjacket'

export const BANDORI_DECK_CHARACTER_ORDER = [3, 2, 4, 1, 5]

export const BANDORI_MUSIC_DIFFICULTY_LIST = [
  'easy', 'normal', 'hard', 'expert', 'special'
]

export const BANDORI_DATA_TYPE = {
  cardList: 'card_list',
  degreeList: 'degree_list',
  songList: 'song_list'
}

export const BANDORI_EVENT_BANNER_URL = {
  jp: 'https://image.mabo.ai/theme/bandori/event/banner/jp',
  cn: 'https://image.mabo.ai/theme/bandori/event/banner/cn'
}

export const TIME_UNIT = [
  {
    unit: 'day',
    description: '天',
    value: 86400
  },
  {
    unit: 'hour',
    description: '小时',
    value: 3600
  },
  {
    unit: 'minute',
    description: '分钟',
    value: 60
  },
  {
    unit: 'second',
    description: '秒',
    value: 1
  }
]
