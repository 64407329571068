<template>
  <div
    class="radio-2-button-container"
    :style="containerStyle"
    @click="select"
  >
    <div class="radio-2-select-container">
      <transition name="fade">
        <div
          v-if="value === currentValue"
          class="radio-2-select"
        />
      </transition>
    </div>
    <div class="radio-2-content-container">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Radio2',
  props: {
    value: {
      type: Number,
      default: 0
    },
    currentValue: {
      type: Number,
      default: 0
    },
    isEnabled: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:currentValue'],
  computed: {
    containerStyle: function () {
      const styleSetting = {}
      if (!this.isEnabled) {
        styleSetting.cursor = 'not-allowed'
        styleSetting.color = 'rgb(200, 200, 200)'
      }
      return styleSetting
    }
  },
  methods: {
    select () {
      if (this.isEnabled) {
        this.$emit('update:currentValue', this.value)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/styles/constants";

.radio-2-button-container {
  display: flex;
  height: 4rem;
  align-items: center;
  cursor: pointer;
}

.radio-2-select-container {
  height: 2.6rem;
  width: 2.6rem;
  margin: 0.5rem;
  border: {
    width: 0.2rem;
    style: solid;
    color: rgb(200, 200, 200);
    radius: 1.5rem;
  };
}

.radio-2-select {
  height: 1.8rem;
  width: 1.8rem;
  background-color: $button_color_1;
  border-radius: 0.9rem;
  margin: 0.4rem;
}

.radio-2-content-container {
  height: 4rem;
  line-height: 4rem;
  font-size: 2rem;
  user-select: none;
  padding: {
    left: 0.2rem;
    right: 1.4rem;
  };
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
