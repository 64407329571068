<template>
  <div
    class="radio-1-button-container"
    :class="(isEnabled && value !== currentValue) ? 'radio-1-button-enabled' : 
      value === currentValue ? 'radio-1-button-checked' : ''"
    :style="containerStyle"
    @click="change"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Radio1',
  props: {
    value: {
      type: Number,
      default: 0
    },
    currentValue: {
      type: Number,
      default: 0
    },
    isEnabled: {
      type: Boolean,
      default: true
    },
    totalNumber: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'update:currentValue',
    'radioOnchange'
  ],
  computed: {
    containerStyle: function () {
      const styleSetting = {}
      if (this.value === 0) {
        if (this.totalNumber < 2) {
          styleSetting.borderRadius = '1.5rem'
        } else {
          styleSetting.borderTopLeftRadius = '1.5rem'
          styleSetting.borderBottomLeftRadius = '1.5rem'
          styleSetting.borderRight = '0.1rem solid white'
        }
      } else if (this.value === this.totalNumber - 1) {
        styleSetting.borderTopRightRadius = '1.5rem'
        styleSetting.borderBottomRightRadius = '1.5rem'
      } else {
        styleSetting.borderRight = '0.1rem solid white'
      }
      if (!this.isEnabled) {
        styleSetting.cursor = 'not-allowed'
        styleSetting.backgroundColor = 'rgb(200, 200, 200)'
      }
      return styleSetting
    }
  },
  methods: {
    change () {
      if (this.value !== this.currentValue) {
        this.$emit('update:currentValue', this.value)
        this.$emit('radioOnchange')
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/styles/constants";

.radio-1-button-container {
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  background-color: $radio_color_1;
  color: white;
  flex-grow: 1;
  text-align: center;
  user-select: none;
  cursor: pointer;
  transition: 0.25s;
}

.radio-1-button-enabled:hover {
  background-color: $radio_color_1_hover;
}

.radio-1-button-checked {
  background-color: $radio_color_1_checked;
}
</style>
