<template>
  <div
    class="bandori-degree-container"
    :style="sizeStyle"
  >
    <div
      v-show="isLoading.base || isLoading.rank || isLoading.icon"
      class="bandori-degree-item"
      :style="sizeStyle"
    >
      <div
        class="bandori-image-loading"
        :style="loadingStyle"
      />
    </div>
    <div v-show="!(isLoading.base || isLoading.rank || isLoading.icon)">
      <div
        ref="bandoriDegreeBase"
        class="bandori-degree-item"
        :style="sizeStyle"
      />
      <div
        v-if="imageSetting.rank !== ''"
        ref="bandoriDegreeRank"
        class="bandori-degree-item"
        :style="sizeStyle"
      />
      <div
        v-if="imageSetting.icon !== ''"
        ref="bandoriDegreeIcon"
        class="bandori-degree-item"
        :style="sizeStyle"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { getDegreeImageURL } from '@/utilities/globalFunctions'

export default defineComponent({
  name: 'BandoriDegree',
  props: {
    setting: {
      type: Object,
      default () {
        return {}
      }
    },
    scale: {
      type: Number,
      default: 0.6
    },
    listeningSetting: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isLoading: {
        base: true,
        rank: true,
        icon: true
      }
    }
  },
  computed: {
    imageSetting: function () {
      return getDegreeImageURL(this.setting, this.setting.server)
    },
    sizeStyle: function () {
      return {
        width: 23 * this.scale + 'rem',
        height: 5 * this.scale + 'rem'
      }
    },
    loadingStyle: function () {
      return {
        width: 2 / 0.6 * this.scale + 'rem',
        height: 2 / 0.6 * this.scale + 'rem',
        margin: (0.5 / 0.6 * this.scale) + 'rem ' + (5.9 / 0.6 * this.scale) + 'rem'
      }
    }
  },
  watch: {
    setting: {
      handler () {
        if (this.listeningSetting) {
          this.loadImage()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.loadImage()
  },
  methods: {
    loadImage: function () {
      this.isLoading.base = true
      this.isLoading.rank = true
      this.isLoading.icon = true

      const baseImage = new Image();
      baseImage.onload = () => {
        if (baseImage.src === this.imageSetting.base && this.$refs.bandoriDegreeBase) {
          this.$refs.bandoriDegreeBase.style.backgroundImage = 'url(' + baseImage.src + ')'
          this.isLoading.base = false
        }
      }
      baseImage.src = this.imageSetting.base

      if (this.imageSetting.rank !== '') {
        const rankImage = new Image();
        rankImage.onload = () => {
          if (rankImage.src === this.imageSetting.rank && this.$refs.bandoriDegreeRank) {
            this.$refs.bandoriDegreeRank.style.backgroundImage = 'url(' + rankImage.src + ')'
            this.isLoading.rank = false
          }
        }
        rankImage.src = this.imageSetting.rank
      } else {
        this.isLoading.rank = false
      }

      if (this.imageSetting.icon !== '') {
        const iconImage = new Image();
        iconImage.onload = () => {
          if (iconImage.src === this.imageSetting.icon && this.$refs.bandoriDegreeIcon) {
            this.$refs.bandoriDegreeIcon.style.backgroundImage = 'url(' + iconImage.src + ')'
            this.isLoading.icon = false
          }
        }
        iconImage.src = this.imageSetting.icon
      } else {
        this.isLoading.icon = false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.bandori-degree-container {
  position: relative;
  display: inline-block;
}

.bandori-degree-item {
  position: absolute;
  background: {
    repeat: no-repeat;
    size: contain;
  };
}
</style>
