<template>
  <div>
    <div
      v-if="isDisplaySkeleton"
      class="skeleton-animation skeleton-block"
      :style="{ height: '3rem' }"
    />
    <div
      v-else
      class="flex-container event-prediction-server-container"
    >
      <Radio1>
        国服
      </Radio1>
    </div>
    <div class="line-container event-prediction-event-banner-container">
      <div
        v-if="isDisplaySkeleton"
        class="skeleton-animation skeleton-block"
        :style="{ height: '15rem' }"
      />
      <div
        v-else
        class="event-prediction-event-banner"
        :style="eventBannerStyle"
      />
    </div>
    <div
      v-if="isDisplaySkeleton"
      class="skeleton-animation line-container skeleton-block"
      :style="{ height: '3rem' }"
    />
    <div
      v-else
      class="line-container flex-container"
    >
      <Radio1
        v-for="(tier, index) in tierList"
        :key="index"
        v-model:currentValue="currentTier"
        :value="index"
        :total-number="tierList.length"
        @radioOnchange="getEventPredictData"
      >
        {{ tier }}
      </Radio1>
    </div>
    <div
      v-if="isDisplaySkeleton"
      class="skeleton-animation line-container skeleton-block"
      :style="{ height: '33.5rem' }"
    />
    <div
      v-else
      class="line-container table"
    >
      <div class="table-row">
        <div class="table-row-title">
          状态
        </div>
        <div class="table-row-content">
          <div>进度：{{ eventData.progress }}%</div>
          <div class="line-container-lite">
            {{ eventData.remainingTimeDescription }}
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="table-row-title">
          最新预测
        </div>
        <div class="table-row-content">
          {{ eventData.predictTierScore }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-row-title">
          最新上报
        </div>
        <div class="table-row-content">
          {{ eventData.latestTrackerScore }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-row-title">
          预测发布时间
        </div>
        <div class="table-row-content">
          {{ eventData.predictTimeDifference }}
        </div>
      </div>
    </div>
    <div
      v-if="isDisplaySkeleton"
      class="skeleton-animation line-container skeleton-block"
      :style="{ height: '30rem' }"
    />
    <div
      v-else
      class="line-container-broad"
      :style="chartContainerStyle"
    >
      <VChart
        class="chart"
        :option="options"
        autoresize
      />
    </div>
    <div
      v-if="isDisplaySkeleton"
      class="skeleton-animation line-container skeleton-block"
      :style="{ height: '2rem' }"
    />
    <div
      v-else
      class="line-container predict-provider-link-container"
    >
      <a
        class="predict-provider-link"
        target="_blank"
        href="https://rinko.com.cn/apps/ycxnowappT.html"
      >
        预测数据由rinko.com.cn提供
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart, ScatterChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from 'echarts/components'
import { getCurrentBandoriEventData, getBandoriEventPredictData } from '@/network/common'
import { BANDORI_EVENT_BANNER_URL } from '@/utilities/constants'
import VChart from 'vue-echarts'
import Radio1 from '@/components/common/Radio1'

// const Spline = require('cubic-spline');

use([
  CanvasRenderer,
  LineChart,
  ScatterChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
])

export default defineComponent({
  name: 'BandoriEventTierPrediction',
  components: {
    Radio1,
    VChart
  },
  data () {
    return {
      serverList: ['cn'],
      currentServer: 0,
      tierList: [100, 1000, 2000],
      currentTier: 0,
      options: {},
      screenWidth: document.body.clientWidth,
      eventData: {
        eventId: 0,
        startAt: 0,
        endAt: 0,
        progress: 0,
        remainingTimeDescription: '',
        predictTierScore: 0,
        latestTrackerScore: 0,
        predictTime: 0,
        predictTimeDifference: ''
      },
      isLoading: {
        eventData: true,
        eventPredict: true,
        firstLoading: true
      },
      latestUpdateTime: 0,
      timer: null
    }
  },
  computed: {
    eventBannerStyle () {
      if (this.eventData.eventId) {
        return {
          backgroundImage: 'url("' + BANDORI_EVENT_BANNER_URL[this.serverList[this.currentServer]] +
            '/banner_event_' + this.eventData.eventId + '.png")'
        }
      } else {
        return {}
      }
    },
    chartContainerStyle () {
      if (this.screenWidth > 574) {
        return {
          height: '40rem'
        }
      } else {
        return {
          height: ((this.screenWidth - 74) / 12.5) + 'rem'
        }
      }
    },
    isDisplaySkeleton () {
      return (this.isLoading.eventData || this.isLoading.eventPredict) && this.isLoading.firstLoading
    }
  },
  mounted () {
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  methods: {
    initialize () {
      this.getEventData()
      this.timer = setInterval(() => {
        this.updateEventStatus()
      }, 250)
    },
    demount () {
      clearInterval(this.timer)
    },
    getEventData () {
      this.isLoading.eventData = true
      getCurrentBandoriEventData(
        { server: 'cn' }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            this.eventData.eventId = responseData.eventId
            this.eventData.startAt = responseData.startAt
            this.eventData.endAt = responseData.endAt
            this.isLoading.eventData = false
            this.getEventPredictData()
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    },
    getEventPredictData () {
      this.isLoading.eventPredict = true
      getBandoriEventPredictData(
        { server: 'cn', tier: this.tierList[this.currentTier] }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            if (responseData.IsPredictEnable) {
              this.latestUpdateTime = Math.round(new Date().getTime() / 1000)
              this.eventData.predictTime = Math.round(new Date(responseData.PresentTime).getTime() / 1000)

              const trackerData = [[0, 0]]
              for (const item of responseData.TrackerNow) {
                trackerData.push([item.pct, item.score])
              }
              this.eventData.latestTrackerScore = trackerData[trackerData.length - 1][1]

              const LRPredictionData = []
              const maxLRPredictionData = []
              if (responseData.LModelPred.length > 1) {
                responseData.LModelPred.forEach((value, index) => {
                  LRPredictionData.push([index, value])
                })
                maxLRPredictionData.push([LRPredictionData[LRPredictionData.length - 1][0], Math.round(LRPredictionData[LRPredictionData.length - 1][1])])
              } else {
                LRPredictionData.push([0, 0])
              }

              const SMRPredictionData = []
              const maxSMRPredictionData = []
              if (!(responseData.ModelPred[responseData.ModelPred.length - 1][1] === 0)) {
                responseData.ModelPred.forEach((value, index) => {
                  SMRPredictionData.push([index, value])
                })
                maxSMRPredictionData.push([SMRPredictionData[SMRPredictionData.length - 1][0], Math.round(SMRPredictionData[SMRPredictionData.length - 1][1])])
              } else {
                SMRPredictionData.push([0, 0])
              }
              this.eventData.predictTierScore = Math.round(SMRPredictionData[SMRPredictionData.length - 1][1])

              // const GSMRPredictionData = []
              // if (!(responseData.GModelPred[responseData.GModelPred.length - 1][1] === 0)) {
              //   responseData.GModelPred.forEach((value, index) => {
              //     GSMRPredictionData.push([index, value])
              //   })
              // } else {
              //   GSMRPredictionData.push([0, 0])
              // }

              this.options = {
                title: {
                  text: '档线趋势预测',
                  x: 'center',
                  y: 'top'
                },
                tooltip: {},
                legend: {
                  x: 'center',
                  y: 'bottom'
                },
                xAxis: {
                  type: 'value',
                  max: 100,
                  min: 0
                },
                grid: {
                  x: 80,
                  y: 60
                },
                yAxis: {
                  type: 'value',
                  min: 0
                },
                series: [
                  {
                    name: '真实档线',
                    type: 'line',
                    symbol: 'circle',
                    symbolSize: 10,
                    smooth: true,
                    color: '#43bfff',
                    data: trackerData,
                    areaStyle: {
                      color: '#c4dcff'
                    },
                    z: 3
                  },
                  {
                    name: '回归模型预测',
                    type: 'line',
                    symbol: 'none',
                    color: '#43bfff',
                    data: LRPredictionData,
                    lineStyle: {
                      type: 'dashed'
                    }
                  },
                  {
                    name: '回归模型预测最大值',
                    type: 'scatter',
                    color: '#43bfff',
                    data: maxLRPredictionData,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          offset: [-34, 0]
                        }
                      }
                    }
                  },
                  {
                    name: 'SMR模型预测',
                    type: 'line',
                    symbol: 'none',
                    color: '#ff4784',
                    data: SMRPredictionData,
                    lineStyle: {
                      type: 'dashed'
                    }
                  },
                  {
                    name: 'SMR模型预测最大值',
                    type: 'scatter',
                    color: '#ff4784',
                    data: maxSMRPredictionData,
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          offset: [-34, 0]
                        }
                      }
                    }
                  }
                ]
              }
              this.isLoading.eventPredict = false
              this.isLoading.firstLoading = false
            } else {
              this.isLoading.firstLoading = false
            }
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    },
    updateEventStatus () {
      if (!this.isLoading.eventData && !this.isLoading.eventPredict) {
        const currentTime = Math.round(new Date().getTime() / 1000)
        if (currentTime >= this.eventData.endAt) {
          this.eventData.progress = 100
          this.eventData.remainingTimeDescription = '已结束'
        } else if (currentTime < this.eventData.startAt) {
          this.eventData.progress = 0
          this.eventData.remainingTimeDescription = '尚未开始'
        } else {
          this.eventData.progress =
            ((currentTime - this.eventData.startAt) / (this.eventData.endAt - this.eventData.startAt) * 100).toFixed(2)
          this.eventData.remainingTimeDescription =
            '剩余' + this.$globalFunctions.getTimeDifferenceDescription(this.eventData.endAt - currentTime)
        }
        this.eventData.predictTimeDifference = this.$globalFunctions.getTimeDifferenceDescription(
          currentTime - this.eventData.predictTime,
          { mode: 'limit', value: 1 }
        ) + '前'

        if (currentTime - this.latestUpdateTime >= 300) {
          this.getEventPredictData()
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/constants";

.event-prediction-server-container {
  height: 3rem;
}

.event-prediction-event-banner-container {
  text-align: center;
}

.event-prediction-event-banner {
  display: inline-block;
  height: 0;
  width: 52.6rem;
  padding-bottom: 17.533rem;
  background: {
    repeat: no-repeat;
    size: contain;
  };
  @media screen and (max-width: 600px) {
    width: 100%;
    padding-bottom: 33.33%;
  }
}

.chart {
  height: 100%;
  width: 100%;
}

.table-row {
  cursor: default;
}

.predict-provider-link-container {
  text-align: right;
}

.predict-provider-link {
  text-decoration: none;
  color: $theme_color;
}
</style>
