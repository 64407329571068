<template>
  <div
    v-if="accountData.list.length === 0"
    class="no-record-hint"
  >
    <span>{{ isSelf ? '您' : '该用户' }}还没有绑定游戏ID哦</span>
  </div>
  <div
    v-else-if="isLoading.cardData || isLoading.degreeData || isLoading.songData"
    class="bandori-profile-container bandori-profile-container-skeleton"
  >
    <div class="bandori-profile-data-container">
      <div class="skeleton-animation bandori-profile-skeleton-bar" />
      <div
        class="skeleton-animation sub-container line-container skeleton-block"
        :style="{ height: '16.5rem' }"
      />
      <div
        class="skeleton-animation sub-container line-container skeleton-block"
        :style="{ height: '14.2rem' }"
      />
      <div
        class="skeleton-animation sub-container line-container skeleton-block"
        :style="{ height: '11.6rem' }"
      />
      <div
        class="skeleton-animation sub-container line-container skeleton-block"
        :style="{ height: '37.8rem' }"
      />
    </div>
  </div>
  <div
    v-else
    class="bandori-profile-container"
  >
    <div
      class="bandori-profile-illustration"
      :style="profileSituationSetting.backgroundStyle"
    />
    <div class="bandori-profile-data-container">
      <div
        class="sub-container"
        :style="{ cursor: 'pointer' }"
        @click="switchDialog('selectGameAccount', true)"
      >
        <div class="line-container flex-container">
          <BandoriLabel
            label="玩家ID"
          />
          <div class="bandori-profile-player-id-container">
            <RegionIcon
              :name="currentAccountServer"
              :size="2"
            />
            <span>{{ currentProfile.userId }}</span>
          </div>
        </div>
      </div>
      <div
        class="bandori-profile-illustration-lite"
        :style="profileSituationSetting.backgroundStyle"
      />
      <div class="sub-container line-container">
        <div
          v-if="mainAccount != null &&
            mainAccount.server === accountData.list[currentAccountIndex].server &&
            mainAccount.player_id === accountData.list[currentAccountIndex].playerID"
          class="bandori-profile-main-mark"
        >
          <font-awesome-icon icon="star" />
        </div>
        <div
          v-if="isSelf"
          class="bandori-profile-manual-sync"
          :class="{ 'bandori-profile-manual-sync-animation': isSynchronizing }"
          @click="$emit('syncProfile', accountData.list[currentAccountIndex])"
        >
          <font-awesome-icon icon="sync-alt" />
        </div>
        <div class="line-container">
          <div class="data-cell data-cell-previous">
            <BandoriCharacterIcon
              :size="6"
              :image-url="profileSituationSetting.data.iconURL"
              :attribute="profileSituationSetting.data.attribute"
              :rarity="profileSituationSetting.data.rarity"
              :is-after-training="profileSituationSetting.data.isAfterTraining"
              style="cursor: pointer"
              @click="openCardDetailInBestDori(profileSituationSetting.data.situationId)"
            />
          </div>
          <div class="data-cell data-cell-previous">
            <BandoriUserRank
              :rank="currentProfile.rank"
            />
          </div>
          <div class="data-cell">
            <div class="bandori-profile-username">
              {{ currentProfile.userName }}
            </div>
          </div>
        </div>
        <div class="flex-container bandori-profile-degree-container">
          <BandoriDegree
            v-for="(item, index) in degreeList"
            :key="index"
            :setting="{
              ...item,
              server: currentAccountServer
            }"
            :style="{ marginTop: '1rem' }"
          />
        </div>
        <div class="line-container">
          <BandoriLabel
            label="自我介绍"
            :content="currentProfile.introduction"
          />
        </div>
      </div>
      <div class="sub-container line-container">
        <div class="line-container">
          <BandoriLabel
            v-if="displayItem.power"
            label="主乐队综合力"
            :content="currentProfile.mainUserDeck.power + (currentProfile.mainUserDeck.isValidPower ? '' : '*')"
            :style="{ cursor: 'pointer' }"
            @click="mainBandPowerNotice"
          />
          <BandoriLabel
            v-else
            label="主乐队"
          />
        </div>
        <div class="flex-container">
          <BandoriCharacterIcon
            v-for="(item, index) in mainDeckSituationSetting"
            :key="index"
            class="bandori-profile-main-band-character"
            :size="8"
            :image-url="item.cardData.iconURL"
            :attribute="item.cardData.attribute"
            :rarity="item.cardData.rarity"
            :band-id="item.cardData.bandId"
            :is-after-training="item.situationData.isAfterTraining"
            :label-text="'等级 ' + item.situationData.level"
            :skill-label-text="item.situationData.skillLevel"
            :skill-type="item.cardData.skillType"
            :display-margin-top="true"
            :style="item.style"
            @click="openCardDetailInBestDori(item.cardData.situationId)"
          />
        </div>
      </div>
      <div
        v-if="displayItem.bandLevel"
        class="sub-container line-container"
      >
        <div class="line-container">
          <BandoriLabel
            label="乐队等级"
          />
        </div>
        <div class="line-container flex-container">
          <BandoriUserBandRank
            v-for="item in bandRankMap"
            :key="item.bandID"
            :band-id="item.bandID"
            :rank="item.rank"
          />
        </div>
      </div>
      <div
        v-if="displayItem.clearMusicCount || displayItem.fullComboMusicCount ||
          displayItem.allPerfectMusicCount || displayItem.highScoreRating"
        class="sub-container line-container"
      >
        <div v-if="displayItem.clearMusicCount">
          <div class="line-container">
            <BandoriLabel
              label="已完成乐曲数"
            />
          </div>
          <div class="line-container flex-container">
            <BandoriMusicCount
              v-for="item of musicCountMap.clearedMusicCountMap"
              :key="item.difficulty"
              :difficulty="item.difficulty"
              :count="item.count"
            />
          </div>
        </div>
        <div v-if="displayItem.fullComboMusicCount">
          <div class="line-container">
            <BandoriLabel
              label="全连击乐曲数"
            />
          </div>
          <div class="line-container flex-container">
            <BandoriMusicCount
              v-for="item of musicCountMap.fullComboMusicCountMap"
              :key="item.difficulty"
              :difficulty="item.difficulty"
              :count="item.count"
            />
          </div>
        </div>
        <div v-if="displayItem.allPerfectMusicCount">
          <div class="line-container">
            <BandoriLabel
              label="全完美乐曲数"
            />
          </div>
          <div class="line-container flex-container">
            <BandoriMusicCount
              v-for="item of musicCountMap.allPerfectMusicCountMap"
              :key="item.difficulty"
              :difficulty="item.difficulty"
              :count="item.count"
            />
          </div>
        </div>
        <div v-if="displayItem.highScoreRating">
          <div class="line-container">
            <BandoriLabel
              label="最高分数排行"
            />
          </div>
          <div class="line-container-lite">
            <BandoriHighScoreRating
              :rating-data="currentProfile.userHighScoreRating"
              :band-setting-list="bandSettingList"
            />
          </div>
        </div>
      </div>
    </div>
    <Modal2
      :display-modal="dialogOpenStatus.selectGameAccount"
      title="选择账号"
      style-type="lite"
      @hideModalEvent="switchDialog('selectGameAccount', false)"
    >
      <Radio2
        v-for="(item, index) in accountData.list"
        :key="index"
        v-model:currentValue="currentAccountIndex"
        :value="index"
        :current-value="currentAccountIndex"
        :style="{ justifyContent: 'space-between' }"
        :class="{ 'border-top': index > 0 }"
      >
        <div
          v-if="item.server === mainAccount.server && item.playerID === mainAccount.player_id"
          class="bandori-profile-main-mark-in-list"
        >
          <font-awesome-icon icon="star" />
        </div>
        <RegionIcon
          :name="item.server"
          :size="2"
          :style="{ marginRight: '0.5rem' }"
        />
        <span>{{ item.playerID }}</span>
      </Radio2>
    </Modal2>
  </div>
</template>

<script>
import BandoriDegree from '@/components/project/Bandori/BandoriDegree'
import BandoriLabel from '@/components/project/Bandori/BandoriLabel'
import BandoriCharacterIcon from '@/components/project/Bandori/BandoriCharacterIcon'
import BandoriUserRank from '@/components/project/Bandori/BandoriUserRank'
import BandoriUserBandRank from '@/components/project/Bandori/BandoriUserBandRank'
import BandoriMusicCount from '@/components/project/Bandori/BandoriMusicCount'
import BandoriHighScoreRating from '@/components/project/Bandori/BandoriHighScoreRating'
import { defineComponent } from 'vue'
import {
  BANDORI_DECK_CHARACTER_ORDER, BANDORI_MUSIC_DIFFICULTY_LIST, BANDORI_DATA_TYPE
} from '@/utilities/constants'
import { getBandoriDataById } from '@/network/common'
import store from '@/store'
import cloneDeep from 'lodash/cloneDeep'
import RegionIcon from '@/components/common/RegionIcon'
import Modal2 from '@/components/common/Modal2'
import Radio2 from '@/components/common/Radio2'

export default defineComponent({
  name: 'BandoriProfile',
  components: {
    Radio2,
    Modal2,
    RegionIcon,
    BandoriHighScoreRating,
    BandoriMusicCount,
    BandoriUserBandRank,
    BandoriUserRank,
    BandoriCharacterIcon,
    BandoriLabel,
    BandoriDegree
  },
  props: {
    profile: {
      type: Object,
      default () {
        return {}
      }
    },
    isSelf: {
      type: Boolean,
      default: true
    },
    mainAccount: {
      type: Object,
      default () {
        return null
      }
    },
    isSynchronizing: {
      type: Boolean,
      default: false
    }
  },
  emits: ['syncProfile'],
  data () {
    return {
      currentAccountIndex: 0,
      isLoading: {
        cardData: true,
        degreeData: true,
        songData: true
      },
      bandSettingList: [
        {
          id: 1,
          name: 'PoppinParty'
        },
        {
          id: 2,
          name: 'Afterglow'
        },
        {
          id: 3,
          name: 'HelloHappyWorld'
        },
        {
          id: 4,
          name: 'PastelPalettes'
        },
        {
          id: 5,
          name: 'Roselia'
        },
        {
          id: 21,
          name: 'Morfonica'
        },
        {
          id: 18,
          name: 'RaiseASuilen'
        },
        {
          id: 0,
          name: 'Other'
        }
      ],
      dialogOpenStatus: {
        selectGameAccount: false
      }
    }
  },
  computed: {
    accountData () {
      const response = {
        list: [],
        profile: []
      }
      for (let server in this.profile) {
        for (let data of this.profile[server]) {
          response.list.push({
            server,
            playerID: data.userId
          })
          response.profile.push(data)
        }
      }
      return response
    },
    currentProfile () {
      return this.accountData.profile[this.currentAccountIndex]
    },
    currentAccountServer () {
      return this.accountData.list[this.currentAccountIndex].server
    },
    displayItem () {
      return {
        power: this.currentProfile.mainUserDeck.power > 0 || this.currentProfile.mainUserDeck.isValidPower,
        bandLevel: !this.$globalFunctions.isEmpty(this.currentProfile.bandRankMap),
        clearMusicCount: !this.$globalFunctions.isEmpty(this.currentProfile.clearedMusicCountMap),
        fullComboMusicCount: !this.$globalFunctions.isEmpty(this.currentProfile.fullComboMusicCountMap),
        allPerfectMusicCount: !this.$globalFunctions.isEmpty(this.currentProfile.allPerfectMusicCountMap),
        highScoreRating: !this.$globalFunctions.isEmpty(this.currentProfile.userHighScoreRating)
      }
    },
    bandRankMap () {
      const bandOrder = [1, 2, 3, 4, 5, 21, 18]
      const response = []
      for (let bandID of bandOrder) {
        if (this.currentProfile.bandRankMap[bandID] !== undefined) {
          response.push({
            bandID,
            rank: this.currentProfile.bandRankMap[bandID]
          })
        }
      }
      return response
    },
    profileSituationSetting () {
      let situationData
      if (this.$globalFunctions.isEmpty(this.currentProfile.userProfileSituation)) {
        situationData = cloneDeep(this.currentProfile.mainDeckUserSituations[0])
      } else {
        if (this.currentProfile.userProfileSituation.viewProfileSituationStatus === 'deck_leader') {
          situationData = cloneDeep(this.currentProfile.mainDeckUserSituations[0])
        } else {
          situationData = cloneDeep(this.currentProfile.userProfileSituation)
        }
      }
      const cardData = cloneDeep(this.$store.state.bandoriData.cardBriefData[situationData.situationId])
      cardData.isAfterTraining = situationData.illust === 'after_training'
      cardData.iconURL = this.$globalFunctions.getCardImageURL(
        this.currentAccountServer, cardData.resourceSetName, 'icon', situationData.illust, cardData.isLocalExist,
        cardData.situationId
      )
      const trimURL = this.$globalFunctions.getCardImageURL(
        this.currentAccountServer, cardData.resourceSetName, 'trim', situationData.illust, cardData.isLocalExist
      )
      return {
        data: cardData,
        backgroundStyle: {
          backgroundImage: 'url("' + trimURL + '")'
        }
      }
    },
    mainDeckSituationSetting () {
      const response = []
      for (let i = 0; i < 5; i++) {
        const situationData = cloneDeep(this.currentProfile.mainDeckUserSituations[i])
        situationData.isAfterTraining = situationData.trainingStatus === 'done'
        const cardData = cloneDeep(this.$store.state.bandoriData.cardBriefData[situationData.situationId])
        cardData.iconURL = this.$globalFunctions.getCardImageURL(
          this.currentAccountServer, cardData.resourceSetName, 'icon', situationData.illust, cardData.isLocalExist,
          cardData.situationId
        )
        response.push({
          situationData,
          cardData,
          style: {
            order: BANDORI_DECK_CHARACTER_ORDER[i],
            cursor: 'pointer'
          }
        })
      }
      return response
    },
    musicCountMap () {
      const response = {}
      const mapNameList = ['clearedMusicCountMap', 'fullComboMusicCountMap', 'allPerfectMusicCountMap']
      for (let mapName of mapNameList) {
        if (this.currentProfile[mapName] !== undefined) {
          const difficultyCount = []
          for (let difficulty of BANDORI_MUSIC_DIFFICULTY_LIST) {
            if (this.currentProfile[mapName][difficulty] !== undefined) {
              difficultyCount.push({
                difficulty,
                count: parseInt(this.currentProfile[mapName][difficulty])
              })
            } else {
              difficultyCount.push({
                difficulty,
                count: 0
              })
            }
          }
          response[mapName] = difficultyCount
        }
      }
      return response
    },
    degreeList () {
      const response = []
      if (this.currentProfile.userProfileDegreeMap.first) {
        response.push(
          this.$store.state.bandoriData.degreeData[this.currentProfile.userProfileDegreeMap.first.degreeId]
        )
      }
      if (this.currentProfile.userProfileDegreeMap.second) {
        response.push(
          this.$store.state.bandoriData.degreeData[this.currentProfile.userProfileDegreeMap.second.degreeId]
        )
      }
      return response
    }
  },
  watch: {
    profile: {
      handler () {
        this.updateBandoriData()
      },
      deep: true
    }
  },
  created () {
    for (let i = 0; i < this.accountData.list.length; i++) {
      if (
        this.accountData.list[i].server === this.mainAccount.server &&
        this.accountData.list[i].playerID === this.mainAccount.player_id
      ) {
        this.currentAccountIndex = i
        break
      }
    }
    this.updateBandoriData()
  },
  methods: {
    updateBandoriData () {
      this.isLoading.cardData = true
      this.isLoading.degreeData = true
      this.isLoading.songData = true
      const update_card_list = []
      const update_degree_list = []
      const update_song_list = []
      for (let playerData of this.accountData.profile) {
        for (let cardData of playerData.mainDeckUserSituations) {
          if (!this.$store.state.bandoriData.cardBriefData[cardData.situationId]) {
            update_card_list.push(cardData.situationId)
          }
        }
        if (
          playerData.userProfileSituation.situationId &&
          !this.$store.state.bandoriData.cardBriefData[playerData.userProfileSituation.situationId]
        ) {
          update_card_list.push(playerData.userProfileSituation.situationId)
        }

        if (!this.$store.state.bandoriData.degreeData[playerData.userProfileDegreeMap.first.degreeId]) {
          update_degree_list.push(playerData.userProfileDegreeMap.first.degreeId)
        }
        if (
          playerData.userProfileDegreeMap.second &&
          !this.$store.state.bandoriData.degreeData[playerData.userProfileDegreeMap.second.degreeId]
        ) {
          update_degree_list.push(playerData.userProfileDegreeMap.second.degreeId)
        }

        for (let bandSetting of this.bandSettingList) {
          const highScoreRatingData = playerData.userHighScoreRating['user' + bandSetting.name + 'HighScoreMusicList']
          if (highScoreRatingData) {
            for (let item of highScoreRatingData) {
              if (!this.$store.state.bandoriData.songData[item.musicId]) {
                update_song_list.push(item.musicId)
              }
            }
          }
        }
      }

      if (update_card_list.length > 0) {
        getBandoriDataById(
          { id: update_card_list, data_type: BANDORI_DATA_TYPE.cardList }
        ).then(response => {
          this.$globalFunctions.handleAPIResponse(
            response,
            responseData => {
              store.commit('bandoriData/updateCardBriefData', responseData)
              this.isLoading.cardData = false
            },
            () => {
              this.isLoading.cardData = false
            }
          )
        }).catch(error => {
          this.$globalFunctions.notify({ content: '请求失败' })
          console.log(error)
        })
      } else {
        this.isLoading.cardData = false
      }

      if (update_degree_list.length > 0) {
        getBandoriDataById(
          { id: update_degree_list, data_type: BANDORI_DATA_TYPE.degreeList }
        ).then(response => {
          this.$globalFunctions.handleAPIResponse(
            response,
            responseData => {
              store.commit('bandoriData/updateDegreeData', responseData)
              this.isLoading.degreeData = false
            },
            () => {
              this.isLoading.degreeData = false
            }
          )
        }).catch(error => {
          this.$globalFunctions.notify({ content: '请求失败' })
          console.log(error)
        })
      } else {
        this.isLoading.degreeData = false
      }

      if (update_song_list.length > 0) {
        getBandoriDataById(
          { id: update_song_list, data_type: BANDORI_DATA_TYPE.songList }
        ).then(response => {
          this.$globalFunctions.handleAPIResponse(
            response,
            responseData => {
              store.commit('bandoriData/updateSongData', responseData)
              this.isLoading.songData = false
            },
            () => {
              this.isLoading.songData = false
            }
          )
        }).catch(error => {
          this.$globalFunctions.notify({ content: '请求失败' })
          console.log(error)
        })
      } else {
        this.isLoading.songData = false
      }
    },
    openCardDetailInBestDori (situationID) {
      window.open('https://bestdori.com/info/cards/' + situationID)
    },
    mainBandPowerNotice () {
      this.$globalFunctions.notify(
        { content: '由于无法获取卡牌剧情通过状态，这里通过卡牌等级推算剧情是否通过，计算出的综合力可能大于实际值。带有*号表示由于部分数据无法获得，计算结果不正确' }
      )
    },
    switchDialog (name, status) {
      this.dialogOpenStatus[name] = status
    }
  }
});
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/constants";

.bandori-profile-container {
  position: relative;
  background: {
    image: url("../../../assets/images/background_pattern.png");
    repeat: repeat;
    size: 17.4rem 13rem;
  }
  padding: 1rem;
  border: {
    bottom-left-radius: 0.5rem;
    bottom-right-radius: 0.5rem;
  };
}

.bandori-profile-container-skeleton {
  background-image: none;
  height: auto;
}

.bandori-profile-data-container {
  position: relative;
  top: unset;
  right: unset;
  width: 100%;
}

.bandori-profile-illustration-lite {
  padding-bottom: 100%;
  background: {
    repeat: no-repeat;
    size: cover;
    position: center bottom;
  };
  margin-bottom: -1rem;
}

@media screen and (min-width: 700px) {
  .bandori-profile-container {
    padding: unset;
  }

  .bandori-profile-container-skeleton {
    height: 94rem;
  }

  .bandori-profile-illustration {
    height: 93rem;
    background: {
      repeat: no-repeat;
      size: cover;
      position: -18rem 0rem;
    };
    @media screen and (max-width: 880px) {
      background-position: -21rem 0rem;
    }
    @media screen and (max-width: 820px) {
      background-position: -24rem 0rem;
    }
    @media screen and (max-width: 760px) {
      background-position: -27rem 0rem;
    }
  }

  .bandori-profile-data-container {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    width: 46rem;
  }

  .bandori-profile-illustration-lite {
    display: none;
  }

  .bandori-profile-player-id-container-inline {
    display: none;
  }
}

.sub-container {
  position: relative;
  border: {
    width: 0.1rem;
    style: solid;
    color: rgb(200, 200, 200);
    radius: 0.6rem;
  };
  padding: 0 1.6rem 1rem;
  background-color: white;
}

.data-cell {
  display: inline-block;
  vertical-align: top;
}

.data-cell-previous {
  margin-right: 0.4rem;
}

.bandori-profile-username {
  font: {
    size: 2.2rem;
    weight: bold;
  };
  line-height: 6rem;
}

@media screen and (min-width: 520px) {
  .data-cell-previous {
    margin-right: 1rem;
  }

  .bandori-profile-username {
    font-size: 2.6rem;
  }

  .bandori-profile-degree-container {
    padding-right: 14rem;
  }
}

.bandori-profile-main-band-character {
  margin: {
    left: 0.2rem;
    right: 0.2rem;
  };
}

.bandori-profile-main-mark {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 0;
  right: 0;
  background: linear-gradient(45deg, transparent 50%, $theme_color 0%);
  background-size: 3rem;
  border-top-right-radius: 0.5rem;
  color: white;
  font-size: 1.2rem;
  svg {
    position: absolute;
    transform: rotate(-27deg);
    top: 0.3rem;
    right: 0.3rem;
  }
}

.bandori-profile-main-mark-in-list {
  display: inline-block;
  color: $theme-color;
  font-size: 1.2rem;
  line-height: 4rem;
  vertical-align: bottom;
  margin-right: 1rem;
  svg {
    transform: rotate(-27deg);
  }
}

.bandori-profile-manual-sync {
  position: absolute;
  top: 3.4rem;
  right: 0.4rem;
  cursor: pointer;
}

.bandori-profile-manual-sync-animation {
  animation: fa-spin 1s linear infinite;
}

.bandori-profile-skeleton-bar {
  height: 3rem;
  border-radius: 1.5rem;
}

.bandori-profile-player-id-container {
  font-weight: bold;
  line-height: 3rem;
  svg {
    vertical-align: text-top;
    margin-right: 0.5rem;
  }
}

.border-top {
  border-top: {
    width: 0.1rem;
    style: solid;
    color: rgb(200, 200, 200);
  };
}
</style>
