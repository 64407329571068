<template>
  <div
    class="container"
    :style="containerStyle"
  >
    <div class="avatar-container">
      <div
        class="user-avatar"
        :style="avatarStyle"
        @click="clickUserAvatar"
      />
    </div>
    <div class="message-container">
      <div class="message-info-container">
        <span class="user-info-username">{{ message.user_info.username }}</span>
        <span class="message-info">来自{{ message.source_info.name }}</span>
        <span class="message-info">{{ isHistory ? message.time : message.time_interval }}</span>
      </div>
      <div v-if="displayPlayerBriefData">
        <div class="line-container-lite">
          <div v-if="isLoading.degreeData || isLoading.cardData">
            <div class="skeleton-animation room-number-skeleton-bar" />
          </div>
          <div v-else>
            <RegionIcon
              :name="message.user_info.bandori_player_brief_info.server"
              :size="2.3"
              class="bandori-brief-info-region"
            />
            <BandoriDegree
              v-for="(item, index) in degreeList"
              :key="index"
              class="bandori-brief-info-degree"
              :setting="{
                ...item,
                server: message.user_info.bandori_player_brief_info.server
              }"
              :scale="0.46"
              :listening-setting="false"
            />
          </div>
        </div>
        <div v-if="isLoading.degreeData || isLoading.cardData">
          <div class="skeleton-animation line-container-lite room-number-skeleton-bar" />
        </div>
        <div
          v-else-if="message.user_info.bandori_player_brief_info.is_valid_power"
          class="line-container-lite flex-container bandori-brief-info-band-power-container"
        >
          <BandoriLabel
            label="综合力"
            :label-style="{
              fontSize: '1.4rem',
              height: '2.4rem',
              lineHeight: '2.4rem',
              paddingLeft: '1.2rem',
              paddingRight: '1.2rem'
            }"
          />
          <span class="bandori-brief-info-band-power">{{ message.user_info.bandori_player_brief_info.band_power }}</span>
        </div>
        <div class="line-container-lite">
          <div v-if="isLoading.degreeData || isLoading.cardData">
            <div
              class="skeleton-animation skeleton-block"
              :style="{
                maxWidth: '24rem',
                height: '4.6rem'
              }"
            />
          </div>
          <div
            v-else
            class="bandori-brief-info-card-icon-container"
          >
            <BandoriCharacterIcon
              v-for="(item, index) in cardList"
              :key="index"
              :size="4.6"
              class="bandori-brief-info-card-icon"
              :image-url="item.cardData.iconURL"
              :attribute="item.cardData.attribute"
              :rarity="item.cardData.rarity"
              :band-id="item.cardData.bandId"
              :is-after-training="item.situationData.isAfterTraining"
              :style="item.style"
            />
          </div>
        </div>
        <div class="line-container room-number-divider" />
      </div>
      <div class="line-container">
        <span
          class="room-number-container"
          title="复制房间号"
          @click="copyRoomNumber"
        >
          <span class="room-number">{{ message.number }}</span>
          <font-awesome-icon icon="copy" />
        </span>
        <span class="room-number-type">{{ message.type }}</span>
      </div>
      <div class="line-container-lite">
        <span class="raw-message">{{ message.raw_message }}</span>
      </div>
      <div
        v-if="!(message.user_info.type === 'local' && message.user_info.user_id === $store.state.account.id)"
        class="line-container operation-button-container"
      >
        <span
          v-if="!isHistory"
          class="operation-button"
          @click="blockUser"
        >屏蔽</span>
        <span
          class="operation-button operation-button-colored"
          @click="informUser"
        >举报</span>
      </div>
    </div>
    <Modal2
      :display-modal="dialogOpenStatus.informUser"
      title="举报用户"
      style-type="lite"
      @hideModalEvent="switchDialog('informUser', false)"
      @modalAfterHide="initializeDialog('informUser')"
    >
      <InformUser
        ref="informUser"
        @hideModalEvent="switchDialog('informUser', false)"
      />
    </Modal2>
  </div>
</template>

<script>
import { ASSETS_URL, BANDORI_DATA_TYPE, BANDORI_DECK_CHARACTER_ORDER } from '@/utilities/constants'
import userAvatarImage from '@/assets/images/user_avatar.png'
import qqUserAvatarImage from '@/assets/images/qq_user_avatar.png'
import { defineComponent } from 'vue';
import useClipboard from 'vue-clipboard3'
import BandoriDegree from '@/components/project/Bandori/BandoriDegree'
import { getBandoriDataById } from '@/network/common'
import store from '@/store'
import cloneDeep from 'lodash/cloneDeep'
import BandoriCharacterIcon from '@/components/project/Bandori/BandoriCharacterIcon'
import BandoriLabel from '@/components/project/Bandori/BandoriLabel'
import RegionIcon from '@/components/common/RegionIcon'
import Modal2 from '@/components/common/Modal2'
import InformUser from '@/components/project/RoomNumber/InformUser'

export default defineComponent({
  name: 'RoomNumberMessage',
  components: {
    InformUser,
    Modal2,
    RegionIcon,
    BandoriLabel,
    BandoriCharacterIcon,
    BandoriDegree
  },
  props: {
    message: {
      type: Object,
      default () {
        return {}
      }
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'addBlockUser',
    'displayUserInfo'
  ],
  setup (props, context) {
    const { toClipboard } = useClipboard()
    const copyRoomNumber = async () => {
      try {
        await toClipboard(props.message.number)
      } catch (e) {
        context.emit('copyRoomNumberError', '复制失败')
      }
    }
    return { copyRoomNumber }
  },
  data () {
    return {
      isLoading: {
        degreeData: true,
        cardData: true
      },
      displayPlayerBriefData: false,
      dialogOpenStatus: {
        informUser: false
      }
    }
  },
  computed: {
    containerStyle () {
      const response = {}
      if (this.message.user_info.is_following) {
        response.borderColor = 'rgba(255, 59, 114, 0.5)'
      }
      return response
    },
    avatarStyle () {
      if (this.message.user_info.avatar) {
        const response = {
          backgroundImage: 'url("' + ASSETS_URL + '/images/user-avatar/' + this.message.user_info.avatar + '")'
        }
        if (!this.isHistory && this.message.user_info.type === 'local') {
          response.cursor = 'pointer'
        }
        return response
      } else {
        if (this.message.user_info.type === 'qq') {
          return {
            backgroundImage: 'url("' + qqUserAvatarImage + '")'
          }
        } else {
          return {
            backgroundImage: 'url("' + userAvatarImage + '")'
          }
        }
      }
    },
    degreeList () {
      const response = []
      if (this.displayPlayerBriefData) {
        for (let item of this.message.user_info.bandori_player_brief_info.degrees) {
          response.push(this.$store.state.bandoriData.degreeData[item])
        }
      }
      return response;
    },
    cardList () {
      const response = []
      if (this.displayPlayerBriefData) {
        for (let i = 0; i < 5; i++) {
          const situationData = cloneDeep(this.message.user_info.bandori_player_brief_info.main_deck[i])
          situationData.isAfterTraining = situationData.trainingStatus === 'done'
          const cardData = cloneDeep(this.$store.state.bandoriData.cardBriefData[situationData.situationId])
          cardData.iconURL = this.$globalFunctions.getCardImageURL(
            this.message.user_info.bandori_player_brief_info.server,
            cardData.resourceSetName,
            'icon',
            situationData.illust,
            cardData.isLocalExist,
            cardData.situationId
          )
          response.push({
            situationData,
            cardData,
            style: {
              order: BANDORI_DECK_CHARACTER_ORDER[i]
            }
          })
        }
      }
      return response
    }
  },
  created () {
    if (this.isHistory) {
      return
    }
    if (this.$globalFunctions.isEmpty(this.message.user_info.bandori_player_brief_info)) {
      this.isLoading.cardData = false
      this.isLoading.degreeData = false
      return
    }
    this.displayPlayerBriefData = true
    this.isLoading.cardData = true
    this.isLoading.degreeData = true
    const update_card_list = []
    const update_degree_list = []
    for (let item of this.message.user_info.bandori_player_brief_info.degrees) {
      if (!this.$store.state.bandoriData.degreeData[item]) {
        update_degree_list.push(item)
      }
    }
    for (let item of this.message.user_info.bandori_player_brief_info.main_deck) {
      if (!this.$store.state.bandoriData.cardBriefData[item.situationId]) {
        update_card_list.push(item.situationId)
      }
    }

    if (update_card_list.length > 0) {
      getBandoriDataById(
        { 'id': update_card_list, 'data_type': BANDORI_DATA_TYPE.cardList }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            store.commit('bandoriData/updateCardBriefData', responseData)
            this.isLoading.cardData = false
          },
          () => {
            this.isLoading.cardData = false
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    } else {
      this.isLoading.cardData = false
    }

    if (update_degree_list.length > 0) {
      getBandoriDataById(
        { 'id': update_degree_list, 'data_type': BANDORI_DATA_TYPE.degreeList }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          responseData => {
            store.commit('bandoriData/updateDegreeData', responseData)
            this.isLoading.degreeData = false
          },
          () => {
            this.isLoading.degreeData = false
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    } else {
      this.isLoading.degreeData = false
    }
  },
  methods: {
    blockUser () {
      this.$emit('addBlockUser', this.message.user_info)
    },
    informUser () {
      if (this.$store.state.account.loginStatus) {
        this.$refs.informUser.updateMessage(this.message)
        this.switchDialog('informUser', true)
      } else {
        this.$router.push('/login')
      }
    },
    clickUserAvatar () {
      if (!this.isHistory && this.message.user_info.type === 'local') {
        this.$emit('displayUserInfo', this.message.user_info.user_id)
      }
    },
    switchDialog (name, status) {
      this.dialogOpenStatus[name] = status
    },
    initializeDialog (ref) {
      this.$refs[ref].initializeData()
    },
  }
})
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/constants";

.container {
  display: flex;
  align-items: flex-start;
  background-color: white;
  border: 0.1rem solid rgba(200, 200, 200, 0.5);
  border-radius: 0.6rem;
  margin-top: 1rem;
  padding: 1rem 1.2rem;
  transition: all 0.5s;
}

.message-container {
  padding-left: 1rem;
  flex-grow: 1;
}

.user-avatar {
  height: 5rem;
  width: 5rem;
  border-radius: 2.5rem;
  //margin-top: 0.6rem;
  background: {
    repeat: no-repeat;
    size: contain;
  };
}

.user-info-username {
  font: {
    size: 1.8rem;
    weight: bold;
  };
  margin-right: 0.6rem;
}

.message-info {
  color: rgb(150, 150, 150);
  font-size: 1.4rem;
  user-select: none;
  margin-right: 0.6rem;
}

.room-number-container {
  cursor: pointer;
  .room-number {
    font-weight: bold;
    margin-right: 0.4rem;
  }
  margin-right: 0.6rem;
}

.operation-button-container {
  text-align: right;
  padding-right: 3.5rem;
  .operation-button {
    user-select: none;
    font-size: 14px;
    cursor: pointer;
    margin: 0 5px;
  }
  .operation-button-colored {
    color: $theme-color;
  }
}

.room-number-divider {
  border-bottom: {
    color: rgb(200, 200, 200);
    style: dotted;
    width: 0.2rem;
  };
}

.raw-message {
  word-break: break-word;
}

.room-number-skeleton-bar {
  max-width: 24rem;
  height: 2.4rem;
  border-radius: 1.2rem;
}
</style>
