<template>
  <div class="page-content scroll-style">
    <transition-group
      id="room-number-list"
      name="flip-list"
      tag="div"
    >
      <RoomNumberMessage
        v-for="message in roomNumberList"
        :key="message.index"
        class="flip-list-item"
        :message="message"
        @addBlockUser="addBlockUser($event)"
        @displayUserInfo="openUserInfo($event)"
      />
    </transition-group>
    <Loading
      v-if="isLoading"
      icon-width="2.4rem"
      icon-height="2.4rem"
    />
    <RoomNumberControlPanel
      ref="roomNumberControlPanel"
      @openDialog="switchDialog($event, true)"
    />
    <div>
      <Modal2
        :display-modal="dialogOpenStatus.sendRoomNumber"
        title="发送房间号"
        style-type="lite"
        @hideModalEvent="switchDialog('sendRoomNumber', false)"
      >
        <SendRoomNumber
          ref="sendRoomNumber"
          :websocket-worker="websocketWorker"
          @hideModalEvent="switchDialog('sendRoomNumber', false)"
        />
      </Modal2>
      <Modal2
        :display-modal="dialogOpenStatus.setRoomNumberFilter"
        title="筛选房间号"
        @hideModalEvent="switchDialog('setRoomNumberFilter', false)"
        @modalAfterHide="initializeDialog('roomNumberFilter')"
      >
        <RoomNumberFilter
          ref="roomNumberFilter"
          :filter="roomNumberFilter"
          @updateFilter="updateRoomNumberFilter()"
        />
      </Modal2>
      <Modal2
        :display-modal="dialogOpenStatus.eventTierPrediction"
        title="活动档线预测"
        @hideModalEvent="switchDialog('eventTierPrediction', false)"
      >
        <BandoriEventTierPrediction
          ref="eventTierPrediction"
        />
      </Modal2>
      <Modal2
        :display-modal="dialogOpenStatus.userInfo"
        :content-padding="false"
        @hideModalEvent="switchDialog('userInfo', false)"
        @modalScroll="$refs.userInfo.checkUpdateRoomNumberHistory($event)"
      >
        <UserInfo ref="userInfo" />
      </Modal2>
    </div>
  </div>
</template>

<script>
import RoomNumberMessage from '@/components/project/RoomNumber/RoomNumberMessage'
import RoomNumberControlPanel from '@/components/project/RoomNumber/RoomNumberControlPanel'
import Modal2 from '@/components/common/Modal2'
import SendRoomNumber from '@/components/project/RoomNumber/SendRoomNumber'
import RoomNumberFilter from '@/components/project/RoomNumber/RoomNumberFilter'
import Loading from '@/components/common/Loading'
import { getRoomNumberFilter } from '@/network/mainAction'
import { defineComponent } from 'vue';
import UserInfo from '@/components/project/Account/UserInfo'
import { ROOM_NUMBER_TYPE } from '@/utilities/constants'
import BandoriEventTierPrediction from '@/components/project/Bandori/BandoriEventTierPrediction'

export default defineComponent({
  name: 'RoomNumberList',
  components: {
    BandoriEventTierPrediction,
    UserInfo,
    RoomNumberMessage,
    RoomNumberControlPanel,
    Modal2,
    RoomNumberFilter,
    SendRoomNumber,
    Loading
  },
  props: {
    websocketWorker: Worker
  },
  emits: ['openDialog'],
  data () {
    return {
      roomNumberList: [],
      roomNumberIndex: 0,
      roomNumberFilter: {
        type: [],
        keyword: [],
        user: []
      },
      timeOffset: 0,
      isLoading: true,
      dialogOpenStatus: {
        sendRoomNumber: false,
        setRoomNumberFilter: false,
        userInfo: false,
        eventTierPrediction: false
      },
      timer: null
    }
  },
  mounted () {
    this.updateRoomNumberFilter()
    this.timer = setInterval(() => {
      this.updateIntervalTime()
    }, 200)
  },
  unmounted () {
    clearInterval(this.timer)
  },
  methods: {
    updateRoomNumberFilter () {
      if (this.$store.state.account.loginStatus) {
        getRoomNumberFilter(
          this.$globalFunctions.generateRequestHeader(this.$store.state.account.token)
        ).then(response => {
          this.$globalFunctions.handleAPIResponse(
            response,
            responseData => {
              if (responseData.room_number_filter) {
                if (responseData.room_number_filter.type &&
                  Array.isArray(responseData.room_number_filter.type)) {
                  this.roomNumberFilter.type = responseData.room_number_filter.type
                }
                if (responseData.room_number_filter.keyword &&
                  Array.isArray(responseData.room_number_filter.keyword)) {
                  this.roomNumberFilter.keyword = responseData.room_number_filter.keyword
                }
                if (responseData.room_number_filter.user &&
                  Array.isArray(responseData.room_number_filter.user)) {
                  this.roomNumberFilter.user = responseData.room_number_filter.user
                }
              }
              this.$refs.roomNumberFilter.initializeData()
            }
          )
        }).catch(
          error => {
            this.$globalFunctions.notify({ content: '请求失败' })
            console.log(error)
          }
        )
      } else {
        if (this.$cookie.isCookieAvailable('roomNumberFilter')) {
          const filter = this.$cookie.getCookie('roomNumberFilter')
          this.$cookie.setCookie('roomNumberFilter', filter, { expire: 2592000 })
          if (filter.type &&
            Array.isArray(filter.type)) {
            this.roomNumberFilter.type = filter.type
          }
          if (filter.keyword &&
            Array.isArray(filter.keyword)) {
            this.roomNumberFilter.keyword = filter.keyword
          }
          if (filter.user &&
            Array.isArray(filter.user)) {
            this.roomNumberFilter.user = filter.user
          }
        }
        this.$refs.roomNumberFilter.initializeData()
      }
    },
    updateRoomNumberList (newData) {
      this.isLoading = false
      const currentTime = new Date().getTime()
      let latestTime
      if (this.roomNumberList.length > 0) {
        latestTime = this.roomNumberList[0].time
      } else {
        latestTime = 0
      }
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].time > latestTime) {
          let filterCheck = true
          for (let j = 0; j < this.roomNumberFilter.type.length; j++) {
            if (newData[i].type === this.roomNumberFilter.type[j]) {
              filterCheck = false
              break
            }
          }
          for (let j = 0; j < this.roomNumberFilter.keyword.length; j++) {
            if (
              this.roomNumberFilter.keyword[j] !== '' &&
              newData[i].raw_message.indexOf(this.roomNumberFilter.keyword[j]) > -1
            ) {
              filterCheck = false
              break
            }
          }
          for (let j = 0; j < this.roomNumberFilter.user.length; j++) {
            if (
              newData[i].user_info.type === this.roomNumberFilter.user[j].type &&
              newData[i].user_info.user_id === this.roomNumberFilter.user[j].user_id
            ) {
              filterCheck = false
              break
            }
          }

          if (filterCheck) {
            newData[i].time_interval = this.translateIntervalTime(
              currentTime + this.timeOffset - newData[i].time
            )
            newData[i].index = this.roomNumberIndex++
            if (newData[i].source_info.name === 'BandoriStation') {
              newData[i].source_info.name = '本站'
            }
            newData[i].type = ROOM_NUMBER_TYPE[newData[i].type]
            if (newData[i].user_info.type === 'local') {
              newData[i].user_info.is_following = !this.$globalFunctions.isEmpty(
                this.$store.state.account.followingUserDict[newData[i].user_info.user_id]
              );
            }
            this.roomNumberList.unshift(newData[i])
          }
        }
      }
    },
    translateIntervalTime (intervalTime) {
      const second = Math.round(intervalTime / 1000)
      const minute = Math.floor(second / 60)
      if (minute > 0) {
        return minute + '分钟前'
      } else {
        return second + '秒前'
      }
    },
    updateIntervalTime () {
      const currentTime = new Date().getTime()
      for (let i = this.roomNumberList.length - 1; i >= 0; i--) {
        const intervalTime = currentTime + this.timeOffset - this.roomNumberList[i].time
        if (intervalTime <= 600000) {
          this.roomNumberList[i].time_interval = this.translateIntervalTime(intervalTime)
        } else {
          this.roomNumberList.pop()
        }
      }
    },
    setTimeDifference (serverTime) {
      const localTime = new Date().getTime()
      this.timeOffset = serverTime - localTime
    },
    switchDialog (name, status) {
      if (name === 'chatRoom') {
        this.$emit('openDialog', name)
      } else {
        if (name === 'eventTierPrediction') {
          if (status) {
            this.$refs.eventTierPrediction.initialize()
          } else {
            this.$refs.eventTierPrediction.demount()
          }
        }
        this.dialogOpenStatus[name] = status
      }
    },
    initializeDialog (ref) {
      this.$refs[ref].initializeData()
    },
    addBlockUser (userInfo) {
      this.$refs.roomNumberFilter.addUser(userInfo)
    },
    setChatUnreadBadge (status) {
      this.$refs.roomNumberControlPanel.setChatUnreadBadge(status)
    },
    openUserInfo (userID) {
      this.$refs.userInfo.initialize(userID)
      this.switchDialog('userInfo', true)
    }
  }
})
</script>

<style lang="scss" scoped>
.flip-list-item {
  transition: all 0.5s;
}

.flip-list-enter-from {
  opacity: 0;
  transform: translateX(-60px);
}

.flip-list-leave-to {
  opacity: 0;
  transform: translateX(60px);
}

.flip-list-leave-active {
  //position: absolute;
}

#room-number-list {
  width: 80rem;
  margin: 2rem auto;
  @media (max-width: 830px) {
    width: calc(100% - 1rem);
  }
  @media (max-width: 600px) {
    margin: {
      top: 1rem;
      bottom: 1rem;
    };
    width: calc(100% - 1rem);
  }
}
</style>
