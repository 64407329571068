import { requestAPI } from '@/network/request'

export function getOnlineNumber () {
  return requestAPI({
    data: {
      function: 'getOnlineNumber'
    }
  })
}

export function getUserBriefInfo (data) {
  return requestAPI({
    data: {
      function: 'getUserBriefInfo',
      ...data
    }
  })
}

export function getBandoriDataById (data) {
  return requestAPI({
    data: {
      function: 'getBandoriDataById',
      ...data
    }
  })
}

export function getCurrentBandoriEventData (data) {
  return requestAPI({
    data: {
      function: 'getCurrentBandoriEventData',
      ...data
    }
  })
}

export function getBandoriEventPredictData (data) {
  return requestAPI({
    data: {
      function: 'getBandoriEventPredictData',
      ...data
    }
  })
}
