<template>
  <div>
    <div>
      <Radio2
        v-model:currentValue="radioValue"
        :value="0"
      >
        设置封禁时长
      </Radio2>
      <div>
        <div class="line-container ban-setting-number-container">
          <Button1
            class="button-2 ban-setting-elements"
            color="1"
            shape="circle"
            :disabled="radioValue === 1"
            :config="{ type: 'button' }"
            @click="accumulate('day', 1)"
          >
            <font-awesome-icon icon="plus" />
          </Button1>
          <Input1
            v-model="banSetting.day"
            :disabled="radioValue === 1"
            class="ban-setting-elements ban-setting-number-input"
          />
          <Button1
            class="button-2 ban-setting-elements"
            color="1"
            shape="circle"
            :disabled="radioValue === 1 || banSetting.day === 0"
            :config="{ type: 'button' }"
            @click="accumulate('day', -1)"
          >
            <font-awesome-icon icon="minus" />
          </Button1>
          <span class="ban-setting-elements">
            天
          </span>
        </div>
        <div class="line-container ban-setting-number-container">
          <Button1
            class="button-2 ban-setting-elements"
            color="1"
            shape="circle"
            :disabled="radioValue === 1"
            :config="{ type: 'button' }"
            @click="accumulate('hour', 1)"
          >
            <font-awesome-icon icon="plus" />
          </Button1>
          <Input1
            v-model="banSetting.hour"
            :disabled="radioValue === 1"
            class="ban-setting-elements ban-setting-number-input"
          />
          <Button1
            class="button-2 ban-setting-elements"
            color="1"
            shape="circle"
            :disabled="radioValue === 1 || banSetting.hour === 0"
            :config="{ type: 'button' }"
            @click="accumulate('hour', -1)"
          >
            <font-awesome-icon icon="minus" />
          </Button1>
          <span class="ban-setting-elements">
            小时
          </span>
        </div>
        <div class="line-container ban-setting-number-container">
          <Button1
            class="button-2 ban-setting-elements"
            color="1"
            shape="circle"
            :disabled="radioValue === 1"
            :config="{ type: 'button' }"
            @click="accumulate('minute', 1)"
          >
            <font-awesome-icon icon="plus" />
          </Button1>
          <Input1
            v-model="banSetting.minute"
            :disabled="radioValue === 1"
            class="ban-setting-elements ban-setting-number-input"
          />
          <Button1
            class="button-2 ban-setting-elements"
            color="1"
            shape="circle"
            :disabled="radioValue === 1 || banSetting.minute === 0"
            :config="{ type: 'button' }"
            @click="accumulate('minute', -1)"
          >
            <font-awesome-icon icon="minus" />
          </Button1>
          <span class="ban-setting-elements">
            分钟
          </span>
        </div>
      </div>
    </div>
    <div class="line-container">
      <Radio2
        v-model:currentValue="radioValue"
        :value="1"
      >
        永久封禁
      </Radio2>
    </div>
    <div class="line-container">
      <Button1
        class="button-1"
        color="1"
        :config="{ type: 'button' }"
        @click="confirmBanUser"
      >
        确定
      </Button1>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Button1 from '@/components/common/Button1'
import Input1 from '@/components/common/Input1'
import Radio2 from '@/components/common/Radio2'
import { banUser } from '@/network/mainAction'

export default defineComponent({
  name: 'BanSetting',
  components: { 
    Radio2, 
    Input1, 
    Button1 
  },
  props: {
    userId: {
      type: Number,
      default: 0
    }
  },
  emits: [
    'updateBanStatus',
    'hideModalEvent'
  ],
  data () {
    return {
      radioValue: 0,
      banSetting: {
        day: 0,
        hour: 0,
        minute: 0
      }
    }
  },
  methods: {
    accumulate (key, value) {
      let currentValue = this.banSetting[key]
      if (!(/(^[1-9]\d*$)/.test(currentValue))) {
        currentValue = 0
      }
      currentValue += value
      if (currentValue <= 0) {
        currentValue = 0
      }
      this.banSetting[key] = currentValue
    },
    confirmBanUser () {
      const intervalDict = {
        day: 0,
        hour: 0,
        minute: 0
      }
      for (let key in intervalDict) {
        if ((/(^[1-9]\d*$)/.test(this.banSetting[key]))) {
          intervalDict[key] = this.banSetting[key]
        }
      }
      const isPermanent = this.radioValue === 1;
      const interval = intervalDict.day * 86400 + intervalDict.hour * 3600 + intervalDict.minute * 60
      if (!isPermanent && interval <= 0) {
        this.$globalFunctions.notify({ content: '封禁时长必须大于0' })
        return
      }
      banUser(
        this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
        { user_id: this.userId, interval, is_permanent: isPermanent }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(
          response,
          () => {
            this.$emit('updateBanStatus', { interval, isPermanent })
            this.$globalFunctions.notify({
              content: '设置成功',
              callback: () => {
                this.$emit('hideModalEvent')
              }
            })
          }
        )
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.ban-setting-number-container {
  display: inline-block;
}

.ban-setting-elements {
  display: inline-block;
  margin-right: 1rem;
}

.ban-setting-number-input {
  width: 8rem;
}
</style>
