<template>
  <div class="table-row bandori-high-score-rating-band-detail-container">
    <div class="bandori-high-score-rating-band-data-cell bandori-high-score-rating-band-summary-container">
      <div>
        <BandoriBandLogo
          :band-id="bandId"
          :size="2.4"
        />
      </div>
      <div>
        {{ totalRating }}
      </div>
    </div>
    <div
      v-for="(item, index) in musicList"
      :key="index"
      class="bandori-high-score-rating-band-data-cell high-score-rating-music-data-container"
    >
      <div class="high-score-rating-music-data-sub-container high-score-rating-music-image-container">
        <div class="high-score-rating-music-image-item">
          <div class="bandori-image-loading bandori-song-loading" />
        </div>
        <div
          class="high-score-rating-music-image-item"
          :style="musicImageStyle[index]"
          @click="openSongDetailInBestDori(item.musicId)"
        />
      </div>
      <div class="high-score-rating-music-data-sub-container high-score-rating-music-rating-container">
        <div>{{ item.difficulty.toUpperCase() }}</div>
        <div>{{ item.rating }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BandoriBandLogo from '@/components/project/Bandori/BandoriBandLogo'

export default defineComponent({
  name: 'BandoriHighScoreRatingBandSummary',
  components: { BandoriBandLogo },
  props: {
    bandId: {
      type: Number,
      default: 0
    },
    musicList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    totalRating: function () {
      let sum = 0
      for (let item of this.musicList) {
        sum += item.rating
      }
      return sum
    },
    musicImageStyle: function () {
      const styleList = []
      for (let item of this.musicList) {
        styleList.push({
          backgroundImage: 'url("' + item.musicImage + '")',
          cursor: 'pointer'
        })
      }
      return styleList
    }
  },
  methods: {
    openSongDetailInBestDori (songId) {
      window.open('https://bestdori.com/info/songs/' + songId)
    }
  }
})
</script>

<style lang="scss" scoped>
.bandori-high-score-rating-band-detail-container {
  cursor: default;
}

.bandori-high-score-rating-band-summary-container {
  padding-right: 1rem;
  div {
    text-align: center;
    width: 5rem;
    line-height: 2rem;
  }
}

.bandori-high-score-rating-band-data-cell {
  display: table-cell;
  vertical-align: middle;
}

.high-score-rating-music-data-container {
  padding: 0.4rem 0;
  @media screen and (min-width: 530px) {
    width: 13rem;
  }
  @media screen and (max-width: 529px) {
    width: 7.4rem;
  }
}

.high-score-rating-music-data-sub-container {
  display: inline-block;
  vertical-align: middle;
  line-height: 2.3rem;
}

.high-score-rating-music-image-container {
  position: relative;
  width: 4.6rem;
  height: 4.6rem;
  margin-left: 0.2rem;
  @media screen and (min-width: 490px) {
    margin: 0 0.4rem;
  }
}

.high-score-rating-music-image-item {
  position: absolute;
  width: 4.6rem;
  height: 4.6rem;
  background: {
    repeat: no-repeat;
    size: contain;
  };
}

.high-score-rating-music-rating-container {
  width: 7.4rem;
}

.bandori-song-loading {
  width: 2.4rem;
  height: 2.4rem;
  margin: 1.1rem;
}
</style>
