<template>
  <div v-if="isActive">
    <slot />
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, inject, computed, watchEffect } from 'vue'

export default defineComponent({
  name: 'Tabs1Pane',
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  setup () {
    const instance = getCurrentInstance();
    const { tabs, active } = inject("tabs1State");

    const index = computed(() =>
      tabs.value.findIndex((target) => target.uid === instance.uid)
    );
    const isActive = computed(() => index.value === active.value);

    watchEffect(() => {
      if (index.value === -1) {
        tabs.value.push(instance);
      }
    });

    return {
      isActive,
    };
  }
})
</script>
