<template>
  <div class="bandori-music-count-container">
    <div
      class="bandori-music-count-label"
      :style="difficultyStyle"
    >
      {{ difficulty.toUpperCase() }}
    </div>
    <div class="bandori-music-count">
      {{ count }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BandoriMusicCount',
  props: {
    difficulty: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    difficultyStyle: function () {
      switch (this.difficulty) {
        case 'easy':
          return {
            backgroundColor: '#4065f5',
            textShadow: '0 0 0.3rem #1838f5'
          }
        case 'normal':
          return {
            backgroundColor: '#81df50',
            textShadow: '0 0 0.3rem #5cc94d'
          }
        case 'hard':
          return {
            backgroundColor: '#f7ce55',
            textShadow: '0 0 0.3rem #f09d4b'
          }
        case 'expert':
          return {
            backgroundColor: '#ea483f',
            textShadow: '0 0 0.3rem #bf3228'
          }
        case 'special':
          return {
            backgroundColor: '#da3b96',
            textShadow: '0 0 0.3rem #ab2365'
          }
        default:
          return {}
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.bandori-music-count-container {
  text-align: center;
  display: inline-block;
}

.bandori-music-count-label {
  width: 7.8rem;
  height: 2.4rem;
  line-height: 2.4rem;
  border-radius: 0.5rem;
  color: white;
  user-select: none;
}

.bandori-music-count {
  font-weight: bold;
  line-height: 2.4rem;
}
</style>