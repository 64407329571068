<template>
  <div
    class="bandori-label-container"
    :style="labelStyle"
  >
    <span class="bandori-label-title">{{ label }}</span>
    <span class="bandori-label-content">{{ content }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BandoriLabel',
  props: {
    label: {
      type: String,
      default: ''
    },
    content: {
      type: [String, Number],
      default: ''
    },
    labelStyle: {
      type: Object,
      default () {
        return {}
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.bandori-label-container {
  display: flex;
  justify-content: space-between;
  background-color: #505050;
  height: 3rem;
  line-height: 3rem;
  color: white;
  border-radius: 1.5rem;
  padding: 0 1.6rem;
  margin: {
    left: -0.2rem;
    right: -0.2rem;
  };
}

.bandori-label-title {
  font-weight: bold;
  user-select: none;
}
</style>
