<template>
  <div>
    <div>
      <Input1
        ref="roomNumber"
        v-model="roomNumber"
        placeholder="房间号"
        @keyup.enter="setFocus('description')"
      />
    </div>
    <div class="line-container flex-container">
      <Radio1
        v-for="(item, index) in roomNumberTypeList"
        :key="index"
        v-model:currentValue="typeIndex"
        :value="index"
        :total-number="roomNumberTypeList.length"
        @radioOnchange="setRoomNumberType"
      >
        {{ item.briefDescription }}
      </Radio1>
    </div>
    <div class="line-container">
      <Textarea1
        ref="description"
        v-model="description"
        placeholder="描述"
        :area-style="{
          height: '8rem'
        }"
        class="description"
        @keyup.enter="confirmSendRoomNumber"
      />
    </div>
    <div class="line-container">
      <Drawer1 title="预选词">
        <div class="line-container">
          <Input1
            id="preselection-word-input"
            v-model="newPreselectionWord"
            placeholder="预选词"
            @keyup.enter="addPreselectionWord"
          />
          <Button1
            id="preselection-word-add-button"
            class="button-1"
            color="3"
            :config="{ type: 'button' }"
            @click="addPreselectionWord"
          >
            添加
          </Button1>
        </div>
        <Pool
          class="line-container"
          title="预选词"
          :data-list="getPreselectionWordList"
          :item-can-click="true"
          :keep-focus="true"
          @removeItem="removePreselectionWord($event)"
          @clickItem="choosePreselectionWord($event)"
        />
      </Drawer1>
    </div>
    <div class="line-container">
      <Button1
        class="button-1"
        color="2"
        :config="{ type: 'button' }"
        @click="initializeData"
      >
        清空房间号
      </Button1>
    </div>
    <div class="line-container">
      <Button1
        class="button-1"
        color="1"
        :config="{ type: 'button' }"
        @click="confirmSendRoomNumber"
      >
        发送
      </Button1>
    </div>
  </div>
</template>

<script>
import Input1 from '@/components/common/Input1'
import Textarea1 from '@/components/common/Textarea1'
import Button1 from '@/components/common/Button1'
import { BANNED_ROOM_NUMBER_PATTERN, BANNED_ROOM_NUMBER_DESCRIPTION_WORD } from '@/utilities/constants'
import { defineComponent } from 'vue'
import Drawer1 from '@/components/common/Drawer1'
import Pool from '@/components/common/Pool'
import Radio1 from '@/components/common/Radio1'
import cloneDeep from 'lodash/cloneDeep'
import { updateWebsiteSetting } from '@/network/clientSetting'
import { ROOM_NUMBER_TYPE_LIST } from '@/utilities/constants'

export default defineComponent({
  name: 'SendRoomNumber',
  components: {
    Pool,
    Drawer1,
    Input1,
    Textarea1,
    Button1,
    Radio1
  },
  props: {
    websocketWorker: Worker
  },
  emits: [
    'hideModalEvent'
  ],
  data () {
    return {
      roomNumber: '',
      typeIndex: 0,
      description: '',
      newPreselectionWord: '',
      preselectionWordDict: {}
    }
  },
  computed: {
    getPreselectionWordList () {
      const wordList = []
      const preselectionWordList = this.$store.state.misc.setting.send_room_number.preselection_word_list
      for (let i = 0; i < preselectionWordList.length; i++) {
        wordList.push({
          id: i,
          content: preselectionWordList[i]
        })
      }
      return wordList
    },
    roomNumberTypeList () {
      return ROOM_NUMBER_TYPE_LIST
    }
  },
  mounted () {
    for (let i = 0; i < ROOM_NUMBER_TYPE_LIST.length; i++) {
      if (ROOM_NUMBER_TYPE_LIST[i].value === this.$store.state.misc.setting.send_room_number.type) {
        this.typeIndex = i
      }
    }

    const preselectionWordDict = {}
    for (let item of this.$store.state.misc.setting.send_room_number.preselection_word_list) {
      preselectionWordDict[item] = true
    }
    this.preselectionWordDict = preselectionWordDict
  },
  methods: {
    initializeData () {
      this.$globalFunctions.notify({
        content: '确认清空房间号及描述吗？',
        displayCancel: true,
        callback: () => {
          this.roomNumber = ''
          this.description = ''
        }
      })
    },
    confirmSendRoomNumber () {
      if (this.roomNumber === '') {
        this.$globalFunctions.notify({ content: '房间号不能为空' })
      } else if (!/^[0-9]{5,6}$/.test(this.roomNumber)) {
        this.$globalFunctions.notify({ content: '请输入正确的房间号' })
      } else if (this.description === '') {
        this.$globalFunctions.notify({ content: '房间描述不能为空' })
      } else if (!this.checkNumber()) {
        this.$globalFunctions.notify({ content: '房间号不合法，请重新确认输入有效的房间号' })
      } else if (!this.checkDescription()) {
        this.$globalFunctions.notify({ content: '房间号描述含有违禁词，请修改后再发送' })
      } else {
        this.websocketWorker.postMessage({
          action: 'sendRoomNumber',
          data: {
            room_number: this.roomNumber,
            description: this.description,
            type: this.roomNumberTypeList[this.typeIndex].value
          }
        })
        this.$emit('hideModalEvent')
      }
    },
    checkNumber () {
      for (const pattern of BANNED_ROOM_NUMBER_PATTERN) {
        if (pattern.test(this.roomNumber)) {
          return false
        }
      }
      return true
    },
    checkDescription () {
      const bannedWordList = JSON.parse(atob(BANNED_ROOM_NUMBER_DESCRIPTION_WORD))
      const description = this.description.replace(/\s*/g, '')
      for (const word of bannedWordList) {
        if (description.indexOf(word) > -1) {
          return false
        }
      }
      return true
    },
    setFocus (ref) {
      this.$refs[ref].focus()
    },
    addPreselectionWord () {
      if (this.newPreselectionWord === '') {
        this.$globalFunctions.notify({ content: '预选词不能为空' })
      } else if (this.preselectionWordDict[this.newPreselectionWord] !== undefined) {
        this.$globalFunctions.notify({ content: '新的预选词与现有的预选词重复，请重新修改后再添加' })
      } else {
        const setting = cloneDeep(this.$store.state.misc.setting)
        setting.send_room_number.preselection_word_list.push(this.newPreselectionWord)
        updateWebsiteSetting(
          this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
          { setting }
        ).then(response => {
          this.$globalFunctions.handleAPIResponse(response, () => {
            this.$store.commit('misc/setSetting', setting)
            this.preselectionWordDict[this.newPreselectionWord] = true
            this.newPreselectionWord = ''
          })
        }).catch(error => {
          this.$globalFunctions.notify({ content: '请求失败' })
          console.log(error)
        })
      }
    },
    choosePreselectionWord (preselectionWord) {
      const currentCursorPosition = this.$refs.description.getSelectionStart()
      let setCursorPosition = false
      if (currentCursorPosition < this.description.length) {
        setCursorPosition = true
      }
      this.description = this.description.substr(0, currentCursorPosition) +
        preselectionWord + this.description.substr(currentCursorPosition)
      if (setCursorPosition) {
        this.$refs.description.setCursorPosition(currentCursorPosition + preselectionWord.length)
      }
    },
    removePreselectionWord (wordIndex) {
      const setting = cloneDeep(this.$store.state.misc.setting)
      const removedWord = setting.send_room_number.preselection_word_list.splice(wordIndex, 1)
      updateWebsiteSetting(
        this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
        { setting }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(response, () => {
          this.$store.commit('misc/setSetting', setting)
          delete this.preselectionWordDict[removedWord]
        })
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    },
    setRoomNumberType () {
      const setting = cloneDeep(this.$store.state.misc.setting)
      setting.send_room_number.type = ROOM_NUMBER_TYPE_LIST[this.typeIndex].value
      updateWebsiteSetting(
        this.$globalFunctions.generateRequestHeader(this.$store.state.account.token),
        { setting }
      ).then(response => {
        this.$globalFunctions.handleAPIResponse(response, () => {
          this.$store.commit('misc/setSetting', setting)
        })
      }).catch(error => {
        this.$globalFunctions.notify({ content: '请求失败' })
        console.log(error)
      })
    }
  }
})
</script>

<style lang="scss" scoped>
#preselection-word-input {
  width: calc(100% - 6.5rem);
  display: inline-block;
  vertical-align: top;
}

#preselection-word-add-button {
  width: 6rem;
  display: inline-block;
  vertical-align: top;
  margin-left: 0.5rem;
}
</style>
