import { createStore } from 'vuex'
import { ASSETS_URL } from '@/utilities/constants'
import userAvatarImage from '@/assets/images/user_avatar.png'

const account = {
  namespaced: true,
  state: () => ({
    initialized: false,
    loginStatus: false,
    token: null,
    id: null,
    avatar: null,
    role: 0,
    banStatus: {
      isBanned: false,
      endTime: 0
    },
    followingUser: [],
    followingUserDict: {}
  }),
  mutations: {
    setInitializeStatus (state, status) {
      state.initialized = status
    },
    setLoginStatus (state, status) {
      state.loginStatus = status
    },
    setToken (state, token) {
      state.token = token
    },
    setID (state, id) {
      state.id = id
    },
    setAvatar (state, imagePath) {
      if (imagePath) {
        state.avatar = ASSETS_URL + '/images/user-avatar/' + imagePath
      } else {
        state.avatar = userAvatarImage
      }
    },
    setRole (state, role) {
      state.role = role
    },
    setBanStatus (state, banStatus) {
      if (banStatus.is_banned) {
        state.banStatus.isBanned = true
        state.banStatus.endTime = new Date().getTime() + banStatus.interval * 1000
      }
    },
    setRoomNumberFilter (state, filterSetting) {
      state.roomNumberFilter = filterSetting
    },
    setFollowingUser (state, followingUserList) {
      state.followingUser = followingUserList
      for (let item of followingUserList) {
        state.followingUserDict[item.user_id] = item
      }
    },
    appendFollowingUser (state, followingUser) {
      if (Array.isArray(followingUser)) {
        for (let i = followingUser.length - 1; i >= 0; i--) {
          state.followingUser.unshift(followingUser[i])
          state.followingUserDict[followingUser[i].user_id] = followingUser[i]
        }
      } else {
        state.followingUser.unshift(followingUser)
        state.followingUserDict[followingUser.user_id] = followingUser
      }
    },
    removeFollowingUser (state, userID) {
      for (let i = 0; i < state.followingUser.length; i++) {
        if (userID === state.followingUser[i].user_id) {
          state.followingUser.splice(i, 1)
          break
        }
      }
      delete state.followingUserDict[userID]
    },
    clearFollowingUser (state) {
      state.followingUser = []
      state.followingUserDict = {}
    }
  }
}

const navbar = {
  namespaced: true,
  state: () => ({
    displayMenu: false
  }),
  mutations: {
    setMenuDisplay (state, isDisplay) {
      state.displayMenu = isDisplay
    }
  }
}

const modal = {
  namespaced: true,
  modules: {
    notice: {
      namespaced: true,
      state: () => ({
        noticeList: []
      }),
      mutations: {
        pushNotice (state, payload) {
          state.noticeList.push({
            id: state.noticeList.length,
            displayModal: true,
            title: payload.title === undefined ? '提示' : payload.title,
            content: payload.content,
            displayCancel: payload.displayCancel === undefined ? false : payload.displayCancel,
            callback: payload.callback === undefined ? null : payload.callback
          })
        },
        hideNotice (state, id) {
          state.noticeList[id].displayModal = false
        },
        removeNotice (state, id) {
          state.noticeList.splice(id, 1)
        }
      }
    },
    dialog: {
      namespaced: true,
      state: () => ({
        VerifyEmail: {
          changeEmail: false
        }
      }),
      mutations: {
        setDisplay (state, payload) {
          state[payload.view][payload.function] = payload.isDisplay
        }
      }
    }
  }
}

const route = {
  namespaced: true,
  state: () => ({
    latestPage: null,
    currentPage: null
  }),
  mutations: {
    updatePage (state, payload) {
      state.latestPage = payload.from
      state.currentPage = payload.to
    }
  }
}

const misc = {
  namespaced: true,
  state: () => ({
    userDeviceType: '',
    setting: {
      background: {
        dynamic_effect: true
      },
      send_room_number: {
        type: 'other',
        preselection_word_list: []
      }
    }
  }),
  mutations: {
    setUserDeviceType (state, device) {
      state.userDeviceType = device
    },
    setSetting (status, setting) {
      status.setting = setting
    }
  }
}

const bandoriData = {
  namespaced: true,
  state: () => ({
    cardBriefData: {},
    degreeData: {},
    songData: {}
  }),
  mutations: {
    updateCardBriefData (state, payload) {
      const keyList = Object.getOwnPropertyNames(payload)
      for (let key of keyList) {
        state.cardBriefData[key] = payload[key]
      }
    },
    updateDegreeData (state, payload) {
      const keyList = Object.getOwnPropertyNames(payload)
      for (let key of keyList) {
        state.degreeData[key] = payload[key]
      }
    },
    updateSongData (state, payload) {
      const keyList = Object.getOwnPropertyNames(payload)
      for (let key of keyList) {
        state.songData[key] = payload[key]
      }
    }
  }
}

export default createStore({
  getters: {
    menuList: (state) => {
      if (state.account.loginStatus) {
        return [
          {
            title: '个人中心',
            type: 'link',
            path: '/account',
            style: 'top'
          },
          {
            title: '网站设置',
            type: 'link',
            path: '/setting',
            style: 'middle'
          },
          {
            title: '关于本站',
            type: 'link',
            path: '/about',
            style: 'middle'
          },
          {
            title: '退出登陆',
            type: 'button',
            function: 'logoutAccount',
            style: 'bottom'
          }
        ]
      } else {
        return [
          {
            title: '登陆',
            type: 'link',
            path: '/login',
            style: 'top'
          },
          {
            title: '网站设置',
            type: 'link',
            path: '/setting',
            style: 'middle'
          },
          {
            title: '关于本站',
            type: 'link',
            path: '/about',
            style: 'bottom'
          }
        ]
      }
    }
  },
  modules: {
    account,
    navbar,
    modal,
    route,
    misc,
    bandoriData
  }
})
