<template>
  <div class="header-1-container">
    <div class="header-1-icon" />
    <div class="header-1-title">
      {{ content }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Title1',
  props: {
    content: {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/styles/constants";

.header-1-container {
  div {
    display: inline-block;
    vertical-align: top;
  }
  .header-1-icon {
    height: 3.3rem;
    width: 2.1rem;
    background: {
      image: url("../../assets/images/exclamation_mark.png");
      repeat: no-repeat;
      size: auto;
    }
  }
  .header-1-title {
    height: 3.3rem;
    width: calc(100% - 3.1rem);
    line-height: 3rem;
    padding-left: 1rem;
    font: {
      size: 2.2rem;
      weight: bold;
    }
    border-bottom: 0.2rem solid $theme_color;
    user-select: none;
  }
}
</style>