<template>
  <transition
    name="modal-display"
    appear
    @after-leave="removeModal"
  >
    <div
      v-show="displayModal"
      class="modal-1-container"
    >
      <div class="modal-1-mask" />
      <div class="modal-1-dialog-container">
        <transition
          name="modal-dialog-display"
          appear
        >
          <div
            v-show="displayModal"
            class="modal-1-dialog"
          >
            <Title1
              :content="title"
            />
            <div class="modal-1-content">
              <slot />
            </div>
            <div class="modal-1-footer">
              <div class="modal-1-footer-button-container">
                <Button1
                  ref="confirm"
                  class="button-1"
                  color="1"
                  :config="{
                    type: 'button'
                  }"
                  @click="hideModal('confirm')"
                >
                  确定
                </Button1>
              </div>
              <div
                v-if="displayCancel"
                class="modal-1-footer-button-container"
              >
                <Button1
                  class="button-1"
                  color="2"
                  :config="{
                    type: 'button'
                  }"
                  @click="hideModal('cancel')"
                >
                  取消
                </Button1>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import Button1 from '@/components/common/Button1'
import { defineComponent } from 'vue'
import Title1 from '@/components/common/Title1'

export default defineComponent({
  name: 'Modal1',
  components: {
    Title1,
    Button1
  },
  props: {
    displayModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '提示'
    },
    displayCancel: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.$refs.confirm.focus()
  },
  methods: {
    hideModal (type) {
      this.$emit('hideModalEvent', type)
    },
    removeModal () {
      this.$emit('removeModalEvent')
    }
  }
})
</script>

<style lang="scss" scoped>
@keyframes fade-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}

.modal-display-enter-active {
  animation: fade-enter 0.3s;
}

.modal-display-leave-active {
  animation: fade-enter 0.3s reverse;
}

.modal-dialog-display-enter-active {
  animation: bounce-in 0.3s;
}

.modal-dialog-display-leave-active {
  animation: bounce-in 0.3s reverse;
}

.modal-1-container, .modal-1-mask, .modal-1-dialog-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-1-container {
  position: fixed;
  z-index: 2000;
}

.modal-1-mask {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-1-dialog-container {
   position: absolute;
   overflow: {
     x: hidden;
     y: auto;
   };
   -webkit-overflow-scrolling: touch;
 }

.modal-1-dialog {
  position: relative;
  width: 40rem;
  min-height: 10rem;
  background-color: white;
  margin: 9rem auto;
  border-radius: 0.6rem;
  padding: 2rem 3rem;
  box-sizing: border-box;
  border: 0.1rem solid rgb(200, 200, 200);
  transition: 0.25s;

  @media screen and (max-width: 411px) {
    width: calc(100% - 1.2rem);
  }
}

.modal-1-content {
  margin-top: 2rem;
}

.modal-1-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  .modal-1-footer-button-container {
    width: 40%;
  }
}
</style>
