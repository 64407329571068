<template>
  <div id="navbar-brand">
    <router-link to="/">
      Bandori <font-awesome-icon icon="star" /> 车站
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavbarBrand'
})
</script>

<style lang="scss" scoped>
#navbar-brand {
  position: relative;
  font-size: 2.4rem;
  color: white;
  width: 18.2rem;
  height: 3.2rem;
  line-height: 3.2rem;
  margin: {
    top: 0.9rem;
    left: 2rem;
  }
  user-select: none;

  a {
    position: absolute;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    white-space: nowrap;

    svg {
      transform: rotate(-27deg);
    }
  }
}
</style>
