import { request } from '@/network/request'

const functionGroup = 'AccountManage'

export function getInitialData (headers) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'getInitialData'
    }
  })
}

export function updateImage (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'updateImage',
      ...data
    }
  })
}

export function updateUsername (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'updateUsername',
      ...data
    }
  })
}

export function updateIntroduction (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'updateIntroduction',
      ...data
    }
  })
}

export function updatePassword (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'updatePassword',
      ...data
    }
  })
}

export function updateEmailSendVerificationCode (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'updateEmailSendVerificationCode',
      ...data
    }
  })
}

export function updateEmailVerifyEmail (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'updateEmailVerifyEmail',
      ...data
    }
  })
}

export function bindQQ (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'bindQQ',
      ...data
    }
  })
}

export function bindGameAccountSetVerificationCode (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'bindGameAccountSetVerificationCode',
      ...data
    }
  })
}

export function reportUnusableVerificationCode (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'reportUnusableVerificationCode',
      ...data
    }
  })
}

export function syncGameAccountData (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'syncGameAccountData',
      ...data
    }
  })
}

export function bindGameAccount (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'bindGameAccount',
      ...data
    }
  })
}

export function unbindGameAccount (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'unbindGameAccount',
      ...data
    }
  })
}

export function setMainGameAccount (headers, data) {
  return request({
    headers,
    data: {
      function_group: functionGroup,
      function: 'setMainGameAccount',
      ...data
    }
  })
}
