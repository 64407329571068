<template>
  <div class="bandori-user-band-rank-container">
    <div class="bandori-user-band-rank-logo">
      <BandoriBandLogo
        :band-id="bandId"
      />
    </div>
    <div class="bandori-user-band-rank">
      {{ rank }}
    </div>
  </div>
</template>

<script>
import BandoriBandLogo from '@/components/project/Bandori/BandoriBandLogo'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BandoriUserBandRank',
  components: {
    BandoriBandLogo
  },
  props: {
    bandId: {
      type: Number,
      default: 0
    },
    rank: {
      type: Number,
      default: 0
    }
  }
})
</script>

<style lang="scss" scoped>
.bandori-user-band-rank-container {
  text-align: center;
  display: inline-block;
}

.bandori-user-band-rank-logo {
  width: 3rem;
  height: 3rem;
}

.bandori-user-band-rank {
  font-weight: bold;
  line-height: 2.4rem;
}
</style>