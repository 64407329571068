<template>
  <div
    id="background-pattern"
    :class="{ 'background-pattern-animation': $store.state.misc.setting.background.dynamic_effect }"
  >
    <!-- 本解决方案由萌萌哒小喵君提供 -->
    <div id="circle-base">
      <div
        id="circle-line"
        :class="{ 'circle-line-animation': $store.state.misc.setting.background.dynamic_effect }"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Background2',
})
</script>

<style lang="scss" scoped>
#background-pattern {
  position: fixed;
  z-index: -1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: {
    image: url("../../assets/images/background_pattern.png");
    repeat: repeat;
    size: 17.4rem 13rem;
  }
}

.background-pattern-animation {
  animation: {
    name: animate-background-pattern;
    duration: 14s;
    timing-function: linear;
    iteration-count: infinite;
  };
}

@keyframes animate-background-pattern {
  from {
    background-position: 0 13rem;
  }
  to {
    background-position: 17.4rem 0;
  }
}

#circle-base {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -13rem;
  margin-left: -13rem;
  width: 26rem;
  height: 26rem;
  background: {
    image: url("../../assets/images/bg_common_circlebase.png");
    repeat: no-repeat;
    size: contain;
  }
}

#circle-line {
  width: 26rem;
  height: 26rem;
  background: {
    image: url("../../assets/images/bg_common_circleline.png");
    repeat: no-repeat;
    size: contain;
  }
}

.circle-line-animation {
  animation: {
    name: animate-background-circle;
    duration: 40s;
    timing-function: linear;
    iteration-count: infinite;
  };
}

@keyframes animate-background-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>