<template>
  <div>
    <label class="input-label">
      <textarea
        ref="input"
        class="input scroll-style"
        :value="modelValue"
        :placeholder="placeholder"
        :style="areaStyle"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Textarea1',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    areaStyle: {
      type: Object,
      default () {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  methods: {
    focus () {
      this.$refs.input.focus()
    },
    getSelectionStart () {
      if (document.activeElement === this.$refs.input) {
        return this.$refs.input.selectionStart
      } else {
        return this.modelValue.length
      }
    },
    setCursorPosition (position) {
      this.$nextTick(() => {
        this.$refs.input.setSelectionRange(position, position)
      })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/styles/constants";

.input-label {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  border-radius: 0.6rem;
  padding: 1rem;
  background-color: rgb(220, 220, 220);
  box-sizing: border-box;
}

.input {
  width: 100%;
  font-size: 1.6rem;
  border: none;
  resize: none;
  -webkit-appearance: none;
  outline: none;
  padding: 0;
  color: $font_color;
  background-color: rgb(220, 220, 220);
  box-sizing: border-box;
  vertical-align: bottom;
}
</style>
