import store from '@/store'
import {
  ERROR_CODE_DEFINITION, BANDORI_CHARACTER_LOCAL_IMAGE_URL,
  BANDORI_CHARACTER_REMOTE_IMAGE_URL, SERVER_ID, BANDORI_DEGREE_REMOTE_IMAGE_URL,
  BANDORI_SONG_REMOTE_IMAGE_URL, TIME_UNIT
} from '@/utilities/constants'

export function notify (payload) {
  store.commit('modal/notice/pushNotice', payload)
}

export function generateRequestHeader (token) {
  return { 'Auth-Token': token }
}

export function handleAPIResponse (response, success, failure) {
  if (response.data.status === 'success') {
    if (response.data.response !== undefined) {
      success(response.data.response)
    } else {
      notify({ content: '未知错误' })
    }
  } else {
    if (failure) {
      failure()
    } else {
      let notice
      if (ERROR_CODE_DEFINITION[response.data.response]) {
        notice = ERROR_CODE_DEFINITION[response.data.response]
      } else {
        notice = response.data.response !== undefined
          ? response.data.response
          : '未知错误'
      }
      notify({ content: notice })
    }
  }
}

export function buttonHold (interval, setCountDownText, afterHold) {
  const timerId = setInterval(() => {
    interval -= 1
    if (interval <= 0) {
      clearInterval(timerId)
      afterHold()
    } else {
      setCountDownText('(' + interval + ')')
    }
  }, 1000)
}

export function checkUserDeviceType () {
  if (navigator.userAgent.match(/Windows NT/i)) {
    store.commit('misc/setUserDeviceType', 'PC')
  } else if (navigator.userAgent.match(/Macintosh/i)) {
    store.commit('misc/setUserDeviceType', 'PC')
  } else if (navigator.userAgent.match(/iPhone/i)) {
    store.commit('misc/setUserDeviceType', 'Mobile')
  } else if (navigator.userAgent.match(/iPad/i)) {
    store.commit('misc/setUserDeviceType', 'Mobile')
  } else if (navigator.userAgent.match(/Android/i)) {
    store.commit('misc/setUserDeviceType', 'Mobile')
  } else if (navigator.userAgent.match(/Windows Phone/i)) {
    store.commit('misc/setUserDeviceType', 'Mobile')
  } else {
    store.commit('misc/setUserDeviceType', 'Other')
  }
}

export function isEmpty (obj) {
  if (!obj && obj !== 0 && obj !== '') { // 检验 undefined 和 null
    return true;
  }
  if (Array.isArray(obj) && obj.length === 0) {
    return true;
  }
  return typeof obj === 'object' && Object.keys(obj).length === 0;
}

export function getCardImageURL (
  server, resourceSetName, imageType, trainingType, isLocalExist,
  situationID = 0
) {
  if (isLocalExist) {
    return BANDORI_CHARACTER_LOCAL_IMAGE_URL[imageType] +
      '/' + resourceSetName + '_' + trainingType + '.png'
  } else {
    if (imageType === 'icon') {
      const iconGroup = (Math.floor(situationID / 50) + 100000).toString().slice(1)
      return BANDORI_CHARACTER_REMOTE_IMAGE_URL[server].icon +
        '/card' + iconGroup + '_rip/' + resourceSetName + '_' + trainingType + '.png'
    } else {
      return BANDORI_CHARACTER_REMOTE_IMAGE_URL[server][imageType] +
        '/' + resourceSetName + '_rip/' + imageType + '_' + trainingType + '.png'
    }
  }
}

export function getDegreeImageURL (degreeData, server) {
  const response = {
    base: BANDORI_DEGREE_REMOTE_IMAGE_URL[server] + '/' +
      degreeData.baseImageName[SERVER_ID[server]] + '.png',
    rank: '',
    icon: ''
  }
  if (degreeData.rank[SERVER_ID[server]] !== 'none') {
    response.rank = BANDORI_DEGREE_REMOTE_IMAGE_URL[server] + '/' +
      degreeData.degreeType[SERVER_ID[server]] + '_' +
      degreeData.rank[SERVER_ID[server]] + '.png'
  }
  if (degreeData.iconImageName[SERVER_ID[server]] !== 'none') {
    response.icon = BANDORI_DEGREE_REMOTE_IMAGE_URL[server] + '/' +
      degreeData.iconImageName[SERVER_ID[server]] + '_' +
      degreeData.rank[SERVER_ID[server]] + '.png'
  }
  return response
}

export function getMusicJacketImageURL (songData) {
  const songGroup = Math.ceil(songData.songId / 10) * 10
  return BANDORI_SONG_REMOTE_IMAGE_URL + '/musicjacket' + songGroup +
    '_rip/assets-star-forassetbundle-startapp-musicjacket-musicjacket' +
    songGroup + '-' + songData.jacketImage[0] + '-jacket.png'
}

export function getTimeDifferenceDescription (
  timeDifference,
  options = { mode: 'full' }
) {
  const timeDict = {}
  for (const item of TIME_UNIT) {
    timeDict[item.unit] = 0
  }

  for (const item of TIME_UNIT) {
    timeDict[item.unit] = Math.floor(timeDifference / item.value)
    timeDifference %= item.value
  }

  let responseString = ''
  let fetchFlag = false
  let fetchCount = 0
  for (const item of TIME_UNIT) {
    if (options.mode === 'precision' && item.unit === options.value) {
      responseString += timeDict[item.unit] + item.description
      break
    }
    if (timeDict[item.unit] === 0) {
      if (!fetchFlag) {
        continue
      }
    } else {
      fetchFlag = true
    }
    responseString += timeDict[item.unit] + item.description
    fetchCount += 1
    if (options.mode === 'limit' && fetchCount >= options.value) {
      break
    }
  }

  return responseString
}
